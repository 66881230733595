import { Table, Grid } from "antd";
import React from "react";
import { GenderTag } from "../tag/GenderTag";
import { CategoryTag } from "../tag/CategoryTag";
import { AgeGroupTag } from "../tag/AgeGroupTag";
import _ from "lodash";
import "./EntriesTable.less";

const { Column } = Table;
const { useBreakpoint } = Grid;

export const EntriesTable = ({ entries, isFetchingEntries, competition }) => {
  const { xxl } = useBreakpoint();

  const hasTeams = entries && _.isNumber(entries[0].team);

  const getSortedEntries = () => {
    let sortedEntries = [...entries];
    if (entries[0].team) {
      sortedEntries.sort((a, b) => {
        if (a.team < b.team) return -1;
        if (a.team > b.team) return 1;
        if (a.teamIndex < b.teamIndex) return -1;
        if (a.teamIndex > b.teamIndex) return 1;
        return 0;
      });
    } else {
      sortedEntries.sort((a, b) => a.id - b.id);
    }

    return sortedEntries;
  };

  const count = _.countBy(entries, "team");

  return (
    <Table
      rowKey={(record) => record.id}
      dataSource={entries && getSortedEntries()}
      pagination={false}
      loading={isFetchingEntries}
      size={"small"}
      rowClassName={(record) =>
        hasTeams
          ? record.team % 2 === 0
            ? "table-even-row-background-color"
            : "table-odd-row-background-color"
          : ""
      }
    >
      {hasTeams && (
        <Column
          title="Cs"
          dataIndex={["team"]}
          key="team"
          width={35}
          render={(value, row) => {
            const obj = {
              children: value,
              props: {},
            };

            if (row.teamIndex === 0) {
              obj.props.rowSpan = count[row.team];
            } else {
              obj.props.rowSpan = 0;
            }

            return obj;
          }}
        />
      )}
      <Column
        title="Név"
        dataIndex={["name"]}
        key="name"
        sorter={hasTeams ? null : (a, b) => a.name.localeCompare(b.name)}
      />
      <Column
        title={xxl ? "Kategória" : "Kateg."}
        dataIndex={["category", "name"]}
        key="category"
        filters={
          hasTeams
            ? null
            : competition.categories.map((category) => {
                return { text: category.name, value: category.id };
              })
        }
        onFilter={(value, record) => record.category.id === value}
        render={(text) => <CategoryTag category={text} />}
        width={xxl ? 110 : 80}
      />
      <Column
        title={xxl ? "Korosztály" : "Koroszt."}
        dataIndex={["ageGroup", "ageGroup", "name"]}
        key="ageGroup"
        filters={
          hasTeams
            ? null
            : competition.ageGroups.map((ageGroup) => {
                return { text: ageGroup.ageGroup.name, value: ageGroup.id };
              })
        }
        onFilter={(value, record) => record.ageGroup.id === value}
        render={(text) => <AgeGroupTag ageGroup={text} />}
        width={xxl ? 110 : 90}
      />
      <Column
        title="Nem"
        dataIndex={["gender"]}
        key="gender"
        filters={
          hasTeams
            ? null
            : [
                {
                  text: "Férfi",
                  value: "MALE",
                },
                { text: "Nő", value: "FEMALE" },
              ]
        }
        onFilter={(value, record) => record.gender === value}
        render={(text) => <GenderTag gender={text} />}
        width={xxl ? 80 : 70}
      />
      <Column
        title="Egyesület"
        dataIndex={["club", "name"]}
        key="club"
        ellipsis={true}
        filters={
          hasTeams
            ? null
            : entries &&
              entries
                .map((entry) => entry.club)
                .filter(
                  (elem, pos, arr) =>
                    arr.findIndex((value) => value.id === elem.id) === pos
                )
                .map((club) => {
                  return { text: club.name, value: club.id };
                })
        }
        onFilter={(value, record) => record.club.id === value}
      />
    </Table>
  );
};
