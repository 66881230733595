import React, { useEffect, useState } from "react";
import { Menu, Grid } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "../auth/Auth";
import { useQuery } from "react-query";
import { getUserData } from "../api/User";
import { useNetworkStatus } from "../networkStatus/NetworkStatus";
import { db } from "../utils/LocalDB";

const { useBreakpoint } = Grid;

export const RightMenu = ({ onClose }) => {
  const networkStatus = useNetworkStatus();
  const auth = useAuth();
  const [localCompetitions, setLocalCompetitions] = useState(null);
  const { data: userData } = useQuery("userData", getUserData, {
    enabled: auth.user,
  });
  const { lg } = useBreakpoint();

  useEffect(() => {
    db.competitions.toArray().then((lc) => setLocalCompetitions(lc));
  }, []);

  return (
    <Menu selectable={false} theme={"dark"} mode={lg ? "horizontal" : "inline"}>
      {auth.user && (
        <>
          {!networkStatus && localCompetitions && (
            <Menu.Item className={"logout-button"}>
              <Link to={"/manager-dashboard"} onClick={onClose}>
                Vezérlőpult
              </Link>
            </Menu.Item>
          )}
          {userData && userData.hasManagedCompetition && (
            <Menu.Item className={"logout-button"}>
              <Link to={"/manager-dashboard"} onClick={onClose}>
                Vezérlőpult
              </Link>
            </Menu.Item>
          )}
          <Menu.Item className={"logout-button"}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                auth.signout();
              }}
            >
              Kijelentkezés
            </div>
          </Menu.Item>
        </>
      )}
      {!auth.user && (
        <Menu.Item className={"login-button"}>
          <Link to={"/login"} onClick={onClose}>
            Bejelentkezés
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );
};
