import React from "react";
import {
  Button,
  PageHeader,
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
  Switch,
  Radio,
  Skeleton,
  Space,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CreateCompetition.less";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { getCategories } from "../api/Category";
import { getAgeGroups } from "../api/AgeGroup";
import { ClubInput } from "../entryForm/ClubInput";
import {
  getManagedCompetition,
  insertCompetition,
  updateCompetition,
} from "../api/Competition";
import { useHistory } from "react-router";
import { getClubs } from "../api/Club";
import { useParams } from "react-router-dom";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const CreateCompetition = () => {
  const cache = useQueryCache();

  let { competitionId } = useParams();

  const [form] = Form.useForm();
  const history = useHistory();

  const { data: categories } = useQuery("categories", getCategories);
  const { data: clubs } = useQuery("clubs", getClubs);

  const { data: ageGroups } = useQuery("agegroups", getAgeGroups);

  const { data: competition, isLoading } = useQuery(
    ["managed-competition", parseInt(competitionId)],
    getManagedCompetition,
    { enabled: competitionId }
  );

  const [mutateCreate, { isLoading: loadingCreate }] = useMutation(
    insertCompetition,
    {
      onSuccess: () => {
        cache.invalidateQueries(["competitions"]);
        form.resetFields();
      },
    }
  );

  const [mutateUpdate, { isLoading: loadingUpdate }] = useMutation(
    updateCompetition,
    {
      onSuccess: () => {
        cache.invalidateQueries(["competitions"]);
        cache.invalidateQueries([
          "managed-competition",
          parseInt(competitionId),
        ]);
        form.resetFields();
      },
    }
  );

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFinish = async (values) => {
    console.log("Success:", values);

    let formValues = { ...values };
    formValues.ageGroups = (values.ageGroups || [])
      .filter((val) => val.ageGroup)
      .map((value) => {
        return {
          id: value.id,
          ageGroup: ageGroups.find((obj) => obj.id === value.ageGroup),
          fromAge: value.fromAge,
          toAge: value.toAge,
        };
      });
    formValues.categories = values.categories.map((value) =>
      categories.find((obj) => obj.id === value)
    );
    formValues.organizerClub = clubs.find(
      (club) => club.id === values.organizerClub
    );
    formValues.startDate = values.startDate[0].utc(true);
    formValues.finishDate = values.startDate[1].utc(true);

    console.log("comp", formValues);

    let newCompetition = null;

    if (competitionId) {
      let competitionToSend = { ...competition, ...formValues };
      newCompetition = await mutateUpdate({
        competitionId,
        competition: competitionToSend,
      });
    } else {
      newCompetition = await mutateCreate(formValues);
    }

    console.log("newcomp", newCompetition);

    if (newCompetition) {
      history.push(`/competition/${newCompetition.id}/${newCompetition.slug}`);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const convertCompetitionToFormInit = (competition) => {
    if (!competition) {
      return { maxParticipants: 999, detailLevel: 1, description: "" };
    }

    let result = { ...competition };
    result.ageGroups = (competition.ageGroups || []).map((ageGroup) => ({
      id: ageGroup.id,
      ageGroup: ageGroup.ageGroup.id,
      fromAge: ageGroup.fromAge,
      toAge: ageGroup.toAge,
    }));
    result.categories = (competition.categories || []).map(
      (category) => category.id
    );
    result.organizerClub = competition.organizerClub.id;
    result.applicationDeadline = moment(competition.applicationDeadline);
    result.unregisterDeadline = moment(competition.unregisterDeadline);
    delete result.createdDate;
    result.startDate = [
      moment(competition.startDate),
      moment(competition.finishDate),
    ];

    console.log("result", result);

    return result;
  };

  return (
    <>
      {isLoading && <Skeleton active paragraph={{ rows: 10 }} />}
      {((competitionId && competition) || !competitionId) && (
        <Form
          form={form}
          {...layout}
          name="basic"
          initialValues={convertCompetitionToFormInit(competition)}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <PageHeader
            className="site-page-header"
            onBack={() =>
              history.push(
                competitionId ? "/manager-dashboard" : "/competitions"
              )
            }
            title={competitionId ? "Verseny módosítása" : "Verseny létrehozása"}
            extra={[
              <Form.Item
                key={1}
                name="published"
                noStyle={true}
                valuePropName="checked"
              >
                <Switch
                  checkedChildren="Nyilvános"
                  unCheckedChildren="Privát"
                />
              </Form.Item>,
              <Button
                type={"primary"}
                key="3"
                htmlType="submit"
                loading={loadingCreate || loadingUpdate}
              >
                {competitionId ? "Verseny módosítása" : "Verseny létrehozása"}
              </Button>,
            ]}
          />
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={9}>
              <Form.Item name="detailLevel" label={" "} colon={false}>
                <Radio.Group
                  buttonStyle="solid"
                  onChange={(event) => {
                    if (event.target.value === 1) {
                      form.setFieldsValue({
                        maxParticipants: null,
                        scoring: [],
                        tiebreaking: [],
                        ageGroups: [],
                      });
                    }
                  }}
                >
                  <Radio.Button value={1}>Csak versenykiírás</Radio.Button>
                  <Radio.Button value={2}>Nevezéssel együtt</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Verseny neve"
                name="name"
                rules={[
                  { required: true, message: "Add meg a verseny nevét!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Verseny típusa"
                name="type"
                rules={[
                  { required: true, message: "Válaszd ki a verseny típusát!" },
                ]}
              >
                <Select
                  onChange={() => {
                    form.setFieldsValue({
                      ageGroups: undefined,
                    });
                  }}
                >
                  <Option key={"THREED"} value={"THREED"}>
                    3D
                  </Option>
                  <Option key={"FIELD"} value={"FIELD"}>
                    Terep
                  </Option>
                  <Option key={"TARGET"} value={"TARGET"}>
                    Pálya
                  </Option>
                  <Option key={"HISTORICAL"} value={"HISTORICAL"}>
                    Történelmi
                  </Option>
                  <Option key={"FUN"} value={"FUN"}>
                    Örömíjász
                  </Option>
                  <Option key={"OTHER"} value={"OTHER"}>
                    Egyéb
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Kategóriák"
                name="categories"
                rules={[
                  { required: true, message: "Válaszd ki a kategóriákat!" },
                ]}
              >
                <Select mode="multiple" allowClear>
                  {categories &&
                    categories.map((category) => (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Verseny napja"
                name="startDate"
                rules={[
                  { required: true, message: "Add meg a verseny dátumát!" },
                ]}
              >
                <RangePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                label="Nevezési határidő"
                name="applicationDeadline"
                rules={[
                  { required: true, message: "Add meg a nevezési határidőt!" },
                ]}
              >
                <DatePicker
                  showTime
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    if (
                      value &&
                      form.getFieldValue("unregisterDeadline") == null
                    ) {
                      form.setFieldsValue({
                        unregisterDeadline: value.clone(),
                      });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Nevezés lemondás határidő"
                name="unregisterDeadline"
                rules={[
                  {
                    required: true,
                    message: "Add meg a nevezés lemondásának határidejét!",
                  },
                ]}
              >
                <DatePicker showTime style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                label="Helyszín"
                name="location"
                rules={[
                  { required: true, message: "Add meg a helyszín nevét!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
                label={"GPS pozíció"}
                required
              >
                <Form.Item
                  name="lat"
                  style={{ display: "inline-block", width: "calc(50% - 6px)" }}
                  rules={[
                    { required: true, message: "Add meg a GPS szélességet!" },
                  ]}
                >
                  <Input
                    style={{ width: "100%" }}
                    placeholder={"Szélesség"}
                    onChange={(event) => {
                      let value = event.currentTarget.value;
                      if (value.indexOf(",") > -1) {
                        let exploded = value.split(",");
                        form.setFieldsValue({
                          lat: exploded[0]
                            .trim()
                            .replace(/[^\d.-]/g, "")
                            .substr(0, 10),
                          lng: exploded[1]
                            .trim()
                            .replace(/[^\d.-]/g, "")
                            .substr(0, 10),
                        });
                      } else {
                        form.setFieldsValue({
                          lat: value
                            .trim()
                            .replace(/[^\d.-]/g, "")
                            .substr(0, 10),
                        });
                      }
                    }}
                  />
                </Form.Item>
                <span
                  style={{
                    display: "inline-block",
                    width: "12px",
                    lineHeight: "32px",
                    textAlign: "center",
                  }}
                ></span>
                <Form.Item
                  name="lng"
                  style={{ display: "inline-block", width: "calc(50% - 6px)" }}
                  rules={[
                    { required: true, message: "Add meg a GPS hosszúságot!" },
                  ]}
                >
                  <Input
                    style={{ width: "100%" }}
                    placeholder={"Hosszúság"}
                    onChange={(event) => {
                      let value = event.currentTarget.value;
                      form.setFieldsValue({
                        lng: value
                          .trim()
                          .replace(/[^\d.-]/g, "")
                          .substr(0, 10),
                      });
                    }}
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item
                label="Szervező egyesület"
                name="organizerClub"
                rules={[
                  {
                    required: true,
                    message: "Válaszd ki a szervező egyesületet!",
                  },
                ]}
              >
                <ClubInput clubs={clubs} />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.detailLevel !== currentValues.detailLevel
                }
              >
                {({ getFieldValue }) => {
                  return getFieldValue("detailLevel") === 2 ? (
                    <Form.Item
                      label="Max résztvevők"
                      name="maxParticipants"
                      rules={[
                        {
                          required: true,
                          message: "Add meg a résztvevők maximális számát!",
                        },
                      ]}
                    >
                      <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.detailLevel !== currentValues.detailLevel
                }
              >
                {({ getFieldValue }) => {
                  return getFieldValue("detailLevel") === 2 ? (
                    <Form.Item
                      label="Találati értékek"
                      name="scoring"
                      dependencies={["detailLevel"]}
                      rules={[
                        {
                          required: true,
                          message:
                            "Válaszd ki a lehetséges találati értékeket!",
                        },
                      ]}
                    >
                      <Select mode="multiple" allowClear>
                        <Option key={"0"} value={"0"}>
                          0
                        </Option>
                        <Option key={"1"} value={"1"}>
                          1
                        </Option>
                        <Option key={"2"} value={"2"}>
                          2
                        </Option>
                        <Option key={"3"} value={"3"}>
                          3
                        </Option>
                        <Option key={"4"} value={"4"}>
                          4
                        </Option>
                        <Option key={"5"} value={"5"}>
                          5
                        </Option>
                        <Option key={"6"} value={"6"}>
                          6
                        </Option>
                        <Option key={"8"} value={"8"}>
                          8
                        </Option>
                        <Option key={"10"} value={"10"}>
                          10
                        </Option>
                        <Option key={"11"} value={"11"}>
                          11
                        </Option>
                        <Option key={"12"} value={"12"}>
                          12
                        </Option>
                        <Option key={"X"} value={"X"}>
                          X
                        </Option>
                      </Select>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.detailLevel !== currentValues.detailLevel
                }
              >
                {({ getFieldValue }) => {
                  return getFieldValue("detailLevel") === 2 ? (
                    <Form.Item
                      label="Pontegyenlőség esetén"
                      name="tiebreaking"
                      dependencies={["detailLevel"]}
                      rules={[
                        {
                          required: true,
                          message:
                            "Válaszd ki hogy pontegyenlőség esetén mi döntsön!",
                        },
                      ]}
                    >
                      <Select mode="multiple" allowClear>
                        <Option key={"T"} value={"T"}>
                          Szétlövés
                        </Option>
                        <Option key={"0"} value={"0"}>
                          Kevesebb 0
                        </Option>
                        <Option key={"1"} value={"1"}>
                          Több 1
                        </Option>
                        <Option key={"2"} value={"2"}>
                          Több 2
                        </Option>
                        <Option key={"3"} value={"3"}>
                          Több 3
                        </Option>
                        <Option key={"4"} value={"4"}>
                          Több 4
                        </Option>
                        <Option key={"5"} value={"5"}>
                          Több 5
                        </Option>
                        <Option key={"6"} value={"6"}>
                          Több 6
                        </Option>
                        <Option key={"8"} value={"8"}>
                          Több 8
                        </Option>
                        <Option key={"10"} value={"10"}>
                          Több 10
                        </Option>
                        <Option key={"11"} value={"11"}>
                          Több 11
                        </Option>
                        <Option key={"12"} value={"12"}>
                          Több 12
                        </Option>
                        <Option key={"X"} value={"X"}>
                          Több X
                        </Option>
                      </Select>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.detailLevel !== currentValues.detailLevel ||
                  prevValues.type !== currentValues.type
                }
              >
                {({ getFieldValue }) => {
                  return getFieldValue("detailLevel") === 2 &&
                    getFieldValue("type") ? (
                    <Form.Item label={"Korosztályok"} required={true}>
                      <Form.List
                        name="ageGroups"
                        rules={[
                          {
                            validator: (rule, value) => {
                              let promise = new Promise((resolve, reject) => {
                                if (!value) {
                                  reject("'ageGroups' is required");
                                } else {
                                  resolve();
                                }
                              });
                              return promise;
                            },
                          },
                        ]}
                      >
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map((field) => (
                              <Space key={field.key} align="baseline">
                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prevValues, curValues) =>
                                    prevValues.ageGroups !== curValues.ageGroups
                                  }
                                >
                                  {() => (
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "ageGroup"]}
                                      fieldKey={[field.fieldKey, "ageGroup"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Válassz kategória nevet",
                                        },
                                      ]}
                                    >
                                      <Select style={{ width: 130 }}>
                                        {(
                                          (ageGroups &&
                                            ageGroups.filter(
                                              (ag) =>
                                                ag.type ===
                                                getFieldValue("type")
                                            )) ||
                                          []
                                        ).map((item) => (
                                          <Option key={item.id} value={item.id}>
                                            {item.name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  )}
                                </Form.Item>
                                <Form.Item
                                  {...field}
                                  label="tól"
                                  name={[field.name, "fromAge"]}
                                  fieldKey={[field.fieldKey, "fromAge"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Add meg a minimum életkort",
                                    },
                                  ]}
                                >
                                  <InputNumber min={0} max={100} />
                                </Form.Item>
                                <Form.Item
                                  {...field}
                                  label="ig"
                                  name={[field.name, "toAge"]}
                                  fieldKey={[field.fieldKey, "toAge"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Add meg a maximum életkort",
                                    },
                                  ]}
                                >
                                  <InputNumber min={0} max={100} />
                                </Form.Item>

                                <MinusCircleOutlined
                                  onClick={() => remove(field.name)}
                                />
                              </Space>
                            ))}

                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                              >
                                Korosztály hozzáadása
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>

              <Form.Item
                label={"Figyelmeztető üzenet"}
                name={"applicationInfoMessage"}
              >
                <Input.TextArea rows={3} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={15}>
              <Form.Item
                name="description"
                rules={[
                  { required: true, message: "Add meg a versenykiírást!" },
                ]}
                wrapperCol={{ span: 24 }}
              >
                <ReactQuill theme="snow" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};
