import Dexie from "dexie";

const db = new Dexie("artemisdb");
db.version(1).stores({
  competitions: "id",
  entries: "id,competition",
  clubs: "id",
  tournaments: "id",
  tournamentEntries: "id,tournament",
});

export { db };
