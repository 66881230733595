import React, { useEffect, useState } from "react";
import { Button, PageHeader, Tabs, Empty, Popconfirm } from "antd";
import { useMutation, useQuery, useQueryCache } from "react-query";
import {
  getManagedCompetition,
  getManagedCompetitionEntries,
  updateEntries,
} from "../api/Competition";
import { useParams } from "react-router-dom";
import { ScoreTab } from "./scoreTab/ScoreTab";
import { PlacementTab } from "./placementTab/PlacementTab";
import { getClubs } from "../api/Club";
import { db } from "../utils/LocalDB";
import { useHistory } from "react-router";
import {
  CloudDownloadOutlined,
  CloudSyncOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useNetworkStatus } from "../networkStatus/NetworkStatus";

const { TabPane } = Tabs;

export const Manager = () => {
  let networkStatus = useNetworkStatus();
  let history = useHistory();
  const cache = useQueryCache();
  let { competitionId, slug } = useParams();
  const [localCompetitionData, setlocalCompetitionData] = useState(null);
  const [localEntries, setLocalEntries] = useState([]);
  const [localClubs, setLocalClubs] = useState([]);

  const { data: entries } = useQuery(
    ["managed-competition-entries", parseInt(competitionId)],
    getManagedCompetitionEntries
  );

  const { data: competition } = useQuery(
    ["managed-competition", parseInt(competitionId)],
    getManagedCompetition
  );

  const { data: clubs } = useQuery("clubs", getClubs);

  const [updateEntriesMutation] = useMutation(updateEntries, {
    onSuccess: () => {
      cache.invalidateQueries([
        "managed-competition-entries",
        parseInt(competitionId),
      ]);
    },
  });

  const getLocalCompetition = async () => {
    let localCompetition = await db.competitions
      .where("id")
      .equals(parseInt(competitionId))
      .toArray();

    console.log("got", localCompetition);

    if (localCompetition[0]) {
      setlocalCompetitionData(localCompetition[0]);
    }
  };

  const getLocalEntries = async () => {
    let localEntries = await db.entries
      .where("competition")
      .equals(parseInt(competitionId))
      .toArray();

    console.log("got", localEntries);

    if (localEntries) {
      setLocalEntries(localEntries);
    }

    return localEntries;
  };

  const getLocalClubs = async () => {
    let localClubs = await db.clubs.toArray();

    console.log("got", localClubs);

    if (localClubs) {
      setLocalClubs(localClubs);
    }

    return localClubs;
  };

  const saveEntriesToLocal = () => {
    db.competitions.put({
      id: parseInt(competitionId),
      name: competition.name,
      scoring: competition.scoring,
      tiebreaking: competition.tiebreaking,
      categories: competition.categories,
      ageGroups: competition.ageGroups,
      syncDate: new Date(),
    });
    db.entries.bulkPut(
      entries.map((entry) => {
        return { ...entry, competition: parseInt(competitionId) };
      })
    );

    db.entries.where("id").above(999999999).delete();

    db.clubs.bulkPut(clubs);

    getLocalEntries();
    getLocalCompetition();
    getLocalClubs();
  };

  const onMountEffect = () => {
    getLocalCompetition();
    getLocalEntries();
    getLocalClubs();
  };

  useEffect(onMountEffect, []);

  const updateEntry = async (entryId, property, value) => {
    console.log("updateentry");
    await db.entries
      .where("id")
      .equals(entryId)
      .modify({ [property]: value });

    await getLocalEntries();
  };

  const createEntry = async (entry) => {
    console.log("createEntry");
    entry.competition = parseInt(competitionId);
    await db.entries.put(entry);

    await getLocalEntries();
  };

  const calculateAndSetPlacement = async (localEntries) => {
    console.log("calculateAndSetPlacement");
    let entries = [...localEntries];

    let categories = {};

    entries.forEach((entry) => {
      entry.tie = false;
      let categoryId = entry.category.id + entry.ageGroup.id + entry.gender;
      if (!categories[categoryId]) {
        categories[categoryId] = [];
      }

      categories[categoryId].push(entry);
    });

    let categoryIdList = Object.keys(categories);

    categoryIdList.forEach((categoryId) => {
      categories[categoryId].sort((a, b) => {
        if (a.score > b.score) {
          return 1;
        }
        if (a.score < b.score) {
          return -1;
        }

        for (let i = 0; i < localCompetitionData.tiebreaking.length; i++) {
          let tiebreakerValue = localCompetitionData.tiebreaking[i];

          if (tiebreakerValue === "0") {
            if (a.s0 < b.s0) {
              return 1;
            }
            if (a.s0 > b.s0) {
              return -1;
            }
          } else if (tiebreakerValue === "T") {
            if (a.tiebreaker > b.tiebreaker) {
              return 1;
            }
            if (a.tiebreaker < b.tiebreaker) {
              return -1;
            }
          } else {
            if (a["s" + tiebreakerValue] > b["s" + tiebreakerValue]) {
              return 1;
            }
            if (a["s" + tiebreakerValue] < b["s" + tiebreakerValue]) {
              return -1;
            }
          }
        }

        a.tie = true;
        b.tie = true;
        return 0;
      });

      categories[categoryId].reverse();

      categories[categoryId].forEach((e, index) => {
        e.placement = index + 1;
      });
    });

    await db.entries.bulkPut(entries);
    getLocalEntries();
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push("/manager-dashboard")}
        title={
          (localCompetitionData && localCompetitionData.name) ||
          `${competitionId} - ${slug}`
        }
        subTitle={
          localCompetitionData
            ? `Szinkronizálva: 
              ${moment(localCompetitionData.syncDate).format(
                "YYYY-MM-DD HH:mm:ss"
              )} - Pontozás: ${localCompetitionData.scoring.join(",")}
              - Tie: ${localCompetitionData.tiebreaking.join(",")}`
            : ""
        }
        extra={[
          entries && competition && (
            <>
              {localCompetitionData && (
                <Popconfirm
                  disabled={!networkStatus}
                  title="A szinkronizálás felülírja a meglévő értékeket. Biztosan folytatod?"
                  onConfirm={() => saveEntriesToLocal()}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button key={"sync"} disabled={!networkStatus}>
                    <CloudSyncOutlined /> Szinkronizálás
                  </Button>
                </Popconfirm>
              )}
              {!localCompetitionData && (
                <Button
                  key={"sync"}
                  onClick={() => saveEntriesToLocal()}
                  disabled={!networkStatus}
                >
                  <CloudDownloadOutlined /> Adatok letöltése az eszközre
                </Button>
              )}
            </>
          ),
          <>
            {localCompetitionData && (
              <Popconfirm
                disabled={!networkStatus}
                title="A feltöltés publikálja az eszközön lévő eredményeket. Biztosan folytatod?"
                onConfirm={() =>
                  updateEntriesMutation({
                    competitionId: localCompetitionData.id,
                    entries: localEntries,
                  })
                }
                okText="Yes"
                cancelText="No"
              >
                <Button key={"update"} disabled={!networkStatus}>
                  <CloudUploadOutlined /> Feltöltés
                </Button>
              </Popconfirm>
            )}
          </>,
        ]}
      />
      {!localCompetitionData && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"Nincsenek letöltött adatok"}
        />
      )}
      {localCompetitionData && (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Pontszámok" key="1">
            {localCompetitionData && (
              <ScoreTab
                localCompetitionData={localCompetitionData}
                localEntries={localEntries}
                localClubs={localClubs}
                calculateAndSetPlacement={calculateAndSetPlacement}
                updateEntry={updateEntry}
                createEntry={createEntry}
                getLocalEntries={getLocalEntries}
              />
            )}
          </TabPane>
          <TabPane tab="Helyezések" key="2" forceRender={true}>
            {localCompetitionData && (
              <PlacementTab
                localCompetitionData={localCompetitionData}
                localEntries={localEntries}
              />
            )}
          </TabPane>
        </Tabs>
      )}
    </div>
  );
};
