import React, { useState } from "react";
import { useQuery } from "react-query";
import { getCompetitions } from "../api/Competition";
import {
  Button,
  Table,
  Space,
  Row,
  Col,
  Tooltip,
  PageHeader,
  Menu,
  Dropdown,
  List,
  Skeleton,
} from "antd";
import { Map } from "../map/Map";
import { Link, NavLink } from "react-router-dom";
import { AimOutlined, MenuOutlined, CarOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { CompetitionTypeTag } from "../tag/CompetitionTypeTag";

const { Column } = Table;

export const Competitions = () => {
  let history = useHistory();
  const { data, isFetching, isLoading } = useQuery(
    "competitions",
    getCompetitions
  );
  const [filteredCompetitions, setFilteredCompetitions] = useState([]);
  const [animatedCompetition, setAnimatedCompetition] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState(null);

  const menu = (record) => (
    <Menu>
      {record.stage !== "SCORED" && (
        <Menu.Item key={1}>
          <NavLink to={`/competition/${record.id}/${record.slug}`}>
            {record.detailLevel === 1 ? "Részletek" : "Nevezés"}
          </NavLink>
        </Menu.Item>
      )}
      {record.stage === "SCORED" && (
        <Menu.Item key={2}>
          <NavLink to={`/competition/${record.id}/${record.slug}`}>
            Eredmények
          </NavLink>
        </Menu.Item>
      )}
      <Menu.Item
        key={3}
        onClick={() =>
          window.open(
            `https://www.google.com/maps/search/?api=1&query=${record.lat},${record.lng}`
          )
        }
      >
        Megtekintés Google Maps-en
      </Menu.Item>
      <Menu.Item
        key={4}
        onClick={() => {
          setAnimatedCompetition(record.id);
          setSelectedCenter({ lat: record.lat, lng: record.lng });
          setTimeout(() => setAnimatedCompetition(null), 1000);
        }}
      >
        Mutasd a térképen
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push("/")}
        title={"Versenyek"}
        extra={[
          <Link key={"create"} to={"/competition/create"}>
            <Button id="create-competition-button" key="2" type="primary">
              Új verseny
            </Button>
          </Link>,
        ]}
      />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={0}>
          <List
            dataSource={data}
            loading={isLoading}
            pagination={{ pageSize: 5 }}
            renderItem={(competition) => (
              <List.Item
                key={competition.id}
                actions={[
                  <Dropdown
                    trigger={"click"}
                    key={"menu-" + competition.id}
                    overlay={menu(competition)}
                    placement="bottomRight"
                  >
                    <Button
                      type={competition.detailLevel === 2 ? "primary" : ""}
                    >
                      <MenuOutlined />
                    </Button>
                  </Dropdown>,
                ]}
              >
                <Skeleton title={false} loading={isLoading} active>
                  <List.Item.Meta
                    title={
                      <>
                        <span>{competition.name}</span>
                      </>
                    }
                    description={
                      <div>
                        <CompetitionTypeTag type={competition.type} />
                        {competition.location} - {competition.startDate}
                      </div>
                    }
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </Col>
        <Col xs={0} sm={24} xl={12}>
          <Table
            dataSource={data}
            loading={isFetching || isLoading}
            onChange={(pagination, filters, sorter, extra) => {
              setFilteredCompetitions(extra.currentDataSource);
            }}
            pagination={{ pageSize: 6 }}
            onSelect
          >
            <Column
              width={90}
              title="Típus"
              dataIndex="type"
              key="type"
              render={(type) => <CompetitionTypeTag type={type} />}
              filters={[
                {
                  text: "3D",
                  value: "THREED",
                },
                {
                  text: "Terep",
                  value: "FIELD",
                },
                {
                  text: "Pálya",
                  value: "TARGET",
                },
                {
                  text: "Történelmi",
                  value: "HISTORICAL",
                },
                {
                  text: "Örömíjász",
                  value: "FUN",
                },
                {
                  text: "Egyéb",
                  value: "OTHER",
                },
              ]}
              onFilter={(value, record) => record.type.indexOf(value) === 0}
            />
            <Column title="Név" dataIndex="name" key="name" />
            <Column
              title="Helyszín"
              dataIndex="location"
              key="location"
              responsive={["sm"]}
            />
            <Column
              title="Dátum"
              dataIndex="startDate"
              key="startDate"
              width={120}
            />
            <Column
              title=""
              key="action"
              align={"right"}
              render={(text, record) => (
                <>
                  <Col xs={{ span: 24 }} xxl={{ span: 0 }}>
                    <Dropdown
                      overlay={menu(record)}
                      placement="bottomRight"
                      trigger={"click"}
                    >
                      <Button type={record.detailLevel === 2 ? "primary" : ""}>
                        <MenuOutlined />
                      </Button>
                    </Dropdown>
                  </Col>
                  <Col xs={{ span: 0 }} xxl={{ span: 24 }}>
                    <Space size="middle">
                      {record.stage !== "SCORED" && (
                        <NavLink
                          to={`/competition/${record.id}/${record.slug}`}
                        >
                          {record.detailLevel === 1 && (
                            <Button style={{ width: "110px" }}>
                              Részletek
                            </Button>
                          )}
                          {record.detailLevel === 2 && (
                            <Button type={"primary"} style={{ width: "110px" }}>
                              Nevezés
                            </Button>
                          )}
                        </NavLink>
                      )}
                      {record.stage === "SCORED" && (
                        <NavLink
                          to={`/competition/${record.id}/${record.slug}`}
                        >
                          <Button style={{ width: "110px" }} type={"primary"}>
                            Eredmények
                          </Button>
                        </NavLink>
                      )}
                      <Tooltip title={"Megnyitás a Google Maps-en"}>
                        <Button
                          href={`https://www.google.com/maps/search/?api=1&query=${record.lat},${record.lng}`}
                          target={"_blank"}
                        >
                          <CarOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip title={"Mutasd a térképen"}>
                        <Button
                          onClick={() => {
                            setAnimatedCompetition(record.id);
                            setSelectedCenter({
                              lat: record.lat,
                              lng: record.lng,
                            });
                            setTimeout(
                              () => setAnimatedCompetition(null),
                              1000
                            );
                          }}
                        >
                          <AimOutlined />
                        </Button>
                      </Tooltip>
                    </Space>
                  </Col>
                </>
              )}
            />
          </Table>
        </Col>

        <Col xs={{ span: 24 }} xl={{ span: 12 }}>
          <Map
            competitions={
              filteredCompetitions.length > 0
                ? filteredCompetitions
                : data || []
            }
            animatedCompetition={animatedCompetition}
            center={selectedCenter}
            zoom={selectedCenter ? 9 : null}
          />
        </Col>
      </Row>
    </>
  );
};
