import React, { useEffect, useState } from "react";
import "./App.less";
import { Switch, Route, useLocation } from "react-router-dom";
import axios from "axios";
import { Home } from "../home/Home";
import { Competitions } from "../competitions/Competitions";
import { Login } from "../login/Login";
import { useHistory } from "react-router";
import { useNetworkStatus } from "../networkStatus/NetworkStatus";

import { Competition } from "../competition/Competition";
import { useQuery } from "react-query";
import { getUserData } from "../api/User";
import { Tournaments } from "../tournaments/Tournaments";
import { Tournament } from "../tournament/Tournament";

import { Layout, Skeleton, Space, Alert } from "antd";
import { TournamentScoreSummary } from "../tournamentScoreSummary/TournamentScoreSummary";
import { CreateCompetition } from "../createCompetition/CreateCompetition";
import { ManageCompetition } from "../manageCompetition/ManageCompetition";
import { Manager } from "../manager/Manager";
import { TournamentManager } from "../tournamentManager/TournamentManager";
import { Register } from "../register/Register";
import { Navbar } from "../menu/NavBar";
import { useAuth } from "../auth/Auth";
import { PrivateRoute } from "../utils/PrivateRoute";
import { useGoogleAnalytics } from "../analytics/Analytics";
import { UserCalendar } from "../userCalendar/UserCalendar";
import { Privacy } from "../privacy/Privacy";
import { UserDataDeletion } from "../privacy/UserDataDeletion";
import { ErrorBoundary } from "../utils/ErrorBoundary";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { ManagerDashboard } from "../managerDashboard/ManagerDashboard";
import { Teams } from "../teams/Teams";

const { Header, Content, Footer } = Layout;

function App() {
  useGoogleAnalytics();
  let location = useLocation();
  let auth = useAuth();
  let history = useHistory();
  let networkStatus = useNetworkStatus();
  let [waitingWorker, setWaitingWorker] = useState(null);
  let [newVersionAvailable, setNewVersionAvailable] = useState(false);

  const updateServiceWorker = (worker) => {
    console.log("updateServiceWorker");

    if (worker) {
      worker.postMessage({ type: "SKIP_WAITING" });
    } else {
      waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    }

    setNewVersionAvailable(false);
    window.location.reload();
  };

  const onServiceWorkerUpdate = (registration) => {
    console.log("onserviceworkerupdate");
    setWaitingWorker(registration && registration.waiting);
    // FORCE SKIP
    updateServiceWorker(registration && registration.waiting);
    //setNewVersionAvailable(true);
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate });
  }, []);

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (
        401 === error.response.status &&
        !error.request.responseURL.endsWith("/auth/login")
      ) {
        console.log("401 received");
        auth.signout();
        history.push("/login");
      } else {
        return Promise.reject(error);
      }
    }
  );

  let searchParams = new URLSearchParams(location.search);
  let authTokenUrlParam = searchParams.get("auth_token");

  if (authTokenUrlParam) {
    auth.tokenSignin(authTokenUrlParam);
    history.replace("/");
  }

  let waitForTokenSignin = false;
  let authTokenLocalStorage = localStorage.getItem("auth_token");
  if (authTokenLocalStorage && authTokenLocalStorage !== "null" && !auth.user) {
    waitForTokenSignin = true;
    auth.tokenSignin(authTokenLocalStorage);
  }

  const { isLoading: isLoadingUserData } = useQuery(["userData"], getUserData, {
    enabled: auth.user,
  });

  if (waitForTokenSignin && !auth.user) {
    return <div>Waiting for sign-in...</div>;
  }

  return (
    <>
      {!networkStatus && <div className={"offline-message"}>Offline</div>}
      {newVersionAvailable && (
        <Alert
          onClick={() => updateServiceWorker()}
          showIcon={false}
          message={
            <span className={"worker-update-message"}>
              Az oldal új verziója érhető el. Kattints ide a frissítéshez!
            </span>
          }
          banner
          type="success"
        />
      )}
      <Layout className="layout">
        <Header>
          <Navbar></Navbar>
        </Header>
        <Content style={{ padding: "54px 50px 0 50px" }}>
          <div className="site-layout-content">
            {isLoadingUserData && <Skeleton active paragraph={{ rows: 10 }} />}
            {!isLoadingUserData && (
              <ErrorBoundary>
                <Switch>
                  <Route path="/manager/:competitionId/:slug">
                    <Manager />
                  </Route>
                  <Route path="/tournament-manager/:tournamentId/:slug">
                    <TournamentManager />
                  </Route>
                  <Route path="/competitions">
                    <Competitions />
                  </Route>
                  <PrivateRoute
                    path="/manager-dashboard"
                    availableOffline={true}
                  >
                    <ManagerDashboard />
                  </PrivateRoute>
                  <PrivateRoute path="/competition/create">
                    <CreateCompetition />
                  </PrivateRoute>
                  <PrivateRoute path="/competition/edit/:competitionId">
                    <CreateCompetition />
                  </PrivateRoute>
                  <Route path="/competition/:competitionId/:slug">
                    <Competition />
                  </Route>
                  <PrivateRoute path="/manage-competition/:competitionId/:slug">
                    <ManageCompetition />
                  </PrivateRoute>
                  <PrivateRoute path="/manage-teams/:competitionId/:slug">
                    <Teams />
                  </PrivateRoute>
                  <PrivateRoute path="/calendar">
                    <UserCalendar />
                  </PrivateRoute>
                  <Route path="/tournaments">
                    <Tournaments />
                  </Route>
                  <Route path="/tournament/:tournamentId/:slug/summary">
                    <TournamentScoreSummary />
                  </Route>
                  <Route path="/tournament/:tournamentId/:slug">
                    <Tournament />
                  </Route>
                  <Route path="/register">
                    <Register />
                  </Route>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/privacy">
                    <Privacy />
                  </Route>
                  <Route path="/user-data-deletion">
                    <UserDataDeletion />
                  </Route>
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              </ErrorBoundary>
            )}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          <Space split={"|"}>
            <span>Íjász.NET</span>
            <span>info@ijasz.net</span>
          </Space>
        </Footer>
      </Layout>
    </>
  );
}

export default App;
