import React, { useState } from "react";
import { GenderInput } from "../../entryForm/GenderInput";
import { CategoryInput } from "../../entryForm/CategoryInput";
import { AgeGroupInput } from "../../entryForm/AgeGroupInput";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Table,
  Tooltip,
  Card,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ClubInput } from "../../entryForm/ClubInput";
import _ from "lodash";

const { Column } = Table;

export const ScoreTab = ({
  localCompetitionData,
  localEntries,
  localClubs,
  updateEntry,
  createEntry,
  getLocalEntries,
  calculateAndSetPlacement,
}) => {
  const [form] = Form.useForm();

  const [filterExpression, setFilterExpression] = useState("");

  const handleFocus = (event) => event.target.select();

  const onInputChange = async (entryId, property, value) => {
    console.log("oninputchange");
    await updateEntry(entryId, property, value);
    let local = await getLocalEntries();
    calculateAndSetPlacement(local);
  };

  const getFilteredData = () => {
    if (filterExpression === "") {
      return localEntries;
    } else if (isNaN(filterExpression)) {
      return localEntries.filter(
        (entry) =>
          entry.name.toLowerCase().indexOf(filterExpression.toLowerCase()) > -1
      );
    } else {
      return localEntries.filter((entry) => {
        return entry.id === parseInt(filterExpression);
      });
    }
  };

  const filterChangeDebounced = _.debounce((e) => {
    setFilterExpression(e.target.value);
  }, 300);

  return (
    <>
      <Row>
        <Col>
          <Input
            id={"filter-expression-input"}
            placeholder={"Keresés..."}
            onChange={filterChangeDebounced}
            onFocus={handleFocus}
            onPressEnter={() => {
              let scoreInput = document.getElementById("score-col-0-0");
              if (scoreInput) {
                scoreInput.focus();
              }
            }}
          />
        </Col>
      </Row>
      <Table dataSource={getFilteredData()} pagination={false} size={"small"}>
        <Column
          title="Azon."
          dataIndex="id"
          key="id"
          width={50}
          render={(colValue) => (colValue > 1000000 ? "-" : colValue)}
        />
        <Column
          title="Név"
          dataIndex="name"
          key="name"
          defaultSortOrder={"ascend"}
          sorter={(a, b) => a.name.localeCompare(b.name)}
        />
        <Column
          title="Egyesület"
          dataIndex={["club", "name"]}
          key="club"
          sorter={(a, b) => a.club.name.localeCompare(b.club.name)}
        />
        <Column
          title="Nem"
          dataIndex="gender"
          key="gender"
          sorter={(a, b) => a.gender.localeCompare(b.gender)}
          render={(colValue, entry) => (
            <GenderInput
              value={colValue}
              onChange={(value) => onInputChange(entry.id, "gender", value)}
            />
          )}
        />
        <Column
          title="Kategória"
          dataIndex="category"
          key="category"
          sorter={(a, b) => a.category.name.localeCompare(b.category.name)}
          render={(colValue, entry) => (
            <CategoryInput
              categories={localCompetitionData.categories}
              value={colValue.id}
              onChange={(value) =>
                onInputChange(
                  entry.id,
                  "category",
                  localCompetitionData.categories.find(
                    (category) => category.id === value
                  )
                )
              }
            />
          )}
        />
        <Column
          title="Korosztály"
          dataIndex="ageGroup"
          key="ageGroup"
          sorter={(a, b) =>
            a.ageGroup.ageGroup.name.localeCompare(b.ageGroup.ageGroup.name)
          }
          render={(colValue, entry) => (
            <AgeGroupInput
              ageGroups={localCompetitionData.ageGroups}
              value={colValue.id}
              onChange={(value) =>
                onInputChange(
                  entry.id,
                  "ageGroup",
                  localCompetitionData.ageGroups.find(
                    (ageGroup) => ageGroup.id === value
                  )
                )
              }
            />
          )}
        />
        {localCompetitionData &&
          [...localCompetitionData.scoring]
            .reverse()
            .map((scoreProperty, colIndex) => (
              <Column
                width={55}
                title={scoreProperty}
                dataIndex={"s" + scoreProperty}
                key={"s" + scoreProperty}
                render={(colValue, entry, index) => (
                  <InputNumber
                    id={`score-col-${index}-${colIndex}`}
                    style={{ width: "55px" }}
                    value={colValue}
                    onFocus={handleFocus}
                    onKeyDown={(e) => {
                      setTimeout(() => {
                        let value = e.target.value;
                        if (value.length == 2) {
                          let nextInput = document.getElementById(
                            `score-col-${index}-${colIndex + 1}`
                          );
                          if (nextInput) {
                            nextInput.focus();
                          } else {
                            let scoreInput = document.getElementById(
                              `score-input-${index}`
                            );

                            scoreInput && scoreInput.focus();
                          }
                        }
                      }, 0);
                    }}
                    onChange={_.debounce((value) => {
                      onInputChange(entry.id, "s" + scoreProperty, value);
                    }, 300)}
                  />
                )}
              />
            ))}
        <Column
          width={70}
          title={"Score"}
          dataIndex={"score"}
          key={"score"}
          sorter={(a, b) => a.score - b.score}
          render={(colValue, entry, index) => (
            <InputNumber
              id={`score-input-${index}`}
              style={{ width: "70px" }}
              value={colValue}
              onFocus={handleFocus}
              onChange={_.debounce(
                (value) => onInputChange(entry.id, "score", value),
                300
              )}
              onPressEnter={() => {
                document.getElementById("filter-expression-input").focus();
              }}
            />
          )}
        />
        <Column
          width={55}
          title={"Tiebreaker"}
          dataIndex={"tiebreaker"}
          key={"tiebreaker"}
          render={(colValue, entry) => (
            <InputNumber
              style={{ width: "55px" }}
              value={colValue}
              onChange={_.debounce(
                (value) => onInputChange(entry.id, "tiebreaker", value),
                300
              )}
            />
          )}
        />
      </Table>
      <Card title={"Új versenyző felvétele"}>
        <Form
          name="entry"
          form={form}
          layout={"vertical"}
          requiredMark={"optional"}
          initialValues={{
            name: "",
            gender: "",
            category: "",
            ageGroup: "",
            club: "",
            lunch: "",
            agree: false,
            profile: "",
          }}
          onFinish={(values) => {
            let entry = { ...values };
            entry.category = localCompetitionData.categories.find(
              (category) => category.id === values.category
            );
            entry.ageGroup = localCompetitionData.ageGroups.find(
              (ageGroup) => ageGroup.id === values.ageGroup
            );
            entry.club = localClubs.find((club) => club.id === values.club);

            entry.id = Math.floor(Math.random() * 1000000) + 1000000000;
            createEntry(entry);
            console.log("onfinish", entry);
          }}
          onFinishFailed={(error) => console.log("onfinishfailed", error)}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label="Név"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Add meg a nevet!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item
                label="Nem"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "Add meg a nemet!",
                  },
                ]}
              >
                <GenderInput />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="Kategória"
                name="category"
                rules={[
                  {
                    required: true,
                    message: "Válaszd ki a kategóriát!",
                  },
                ]}
              >
                <CategoryInput categories={localCompetitionData.categories} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label={
                  <div>
                    <Space>
                      <span>Korosztály</span>
                      <Tooltip
                        placement="rightTop"
                        title={"A január 1-én betöltött kor számít."}
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Space>
                  </div>
                }
                name="ageGroup"
                rules={[
                  {
                    required: true,
                    message: "Válaszd ki a korosztályt!",
                  },
                ]}
              >
                <AgeGroupInput ageGroups={localCompetitionData.ageGroups} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Egyesület"
                name="club"
                rules={[
                  {
                    required: true,
                    message: "Válaszd ki az egyesületet!",
                  },
                ]}
              >
                <ClubInput clubs={localClubs} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Nevezés leadása
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};
