import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactQueryDevtools } from "react-query-devtools";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { ProvideAuth } from "./auth/Auth";

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <ReactQueryDevtools />
        <ProvideAuth>
          <App />
        </ProvideAuth>
      </ReactQueryCacheProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
