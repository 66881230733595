import React, { useState } from "react";
import { LeftMenu } from "./LeftMenu";
import { RightMenu } from "./RightMenu";
import { Drawer, Button } from "antd";
import "./NavBar.less";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <nav className="menuBar">
      <div className="logo">
        <img alt={"logo"} src={"/logo_100x100.png"} />
        <Link to={"/"}>ÍJÁSZ.NET</Link>
      </div>
      <div className="menuCon">
        <div className="leftMenu">
          <LeftMenu onClose={onClose} />
        </div>
        <div className="rightMenu">
          <RightMenu onClose={onClose} />
        </div>
        <Button className="barsMenu" type="primary" onClick={showDrawer}>
          <span className="barsBtn"></span>
        </Button>
        <Drawer
          title="Menü"
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <LeftMenu onClose={onClose} />
          <RightMenu onClose={onClose} />
        </Drawer>
      </div>
    </nav>
  );
};
