import React from "react";
import { groupEntries } from "../utils/CompetitionEntryUtils";
import { Grid, Card, Table, PageHeader, Skeleton, Empty } from "antd";
import { useQuery } from "react-query";
import { getTournament, getTournamentEntries } from "../api/Tournament";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";

const { Column } = Table;
const { useBreakpoint } = Grid;

export const TournamentScoreSummary = () => {
  let history = useHistory();
  const { lg, xl } = useBreakpoint();

  let { tournamentId } = useParams();

  const { data: tournament } = useQuery(
    ["tournament", parseInt(tournamentId)],
    getTournament
  );

  const {
    data: tournamentEntries,
    isLoading: isLoadingTournamentEntries,
  } = useQuery(
    ["tournament-entries", parseInt(tournamentId)],
    getTournamentEntries
  );

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() =>
          history.push(`/tournament/${tournament.id}/${tournament.slug}`)
        }
        title={tournament && tournament.name}
      />

      {isLoadingTournamentEntries && (
        <Skeleton active paragraph={{ rows: 10 }} />
      )}

      {!isLoadingTournamentEntries && tournamentEntries.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"Még nem zajlott le az első forduló!"}
        />
      )}

      {tournamentEntries &&
        Object.entries(groupEntries(tournamentEntries)).map(([key, value]) => {
          value.entries.sort(
            (a, b) => (a.placement || 999) - (b.placement || 999)
          );
          return (
            <Card
              key={key}
              title={`${value.category.name} ${value.ageGroup.name} ${
                value.gender === "MALE" ? "Férfi" : "Nő"
              }`}
              style={{ marginBottom: "16px" }}
              bodyStyle={{ padding: xl ? "16px" : 0 }}
            >
              <Table
                bordered
                dataSource={value.entries}
                pagination={false}
                size={"small"}
                rowKey={(row) => row.id}
                rowClassName={(record) =>
                  record.tie ? "tie" : "placement-" + record.placement
                }
                scroll={lg ? { x: 1300 } : false}
              >
                <Column
                  width={30}
                  title="#"
                  dataIndex="placement"
                  key="placement"
                  fixed={true}
                />
                <Column title="Név" dataIndex="name" key="name" fixed={true} />
                <Column
                  title="Egyesület"
                  dataIndex={["club", "name"]}
                  key="club"
                />

                {tournament &&
                  tournament.competitions
                    .map((competition) => competition.id)
                    .map((competitionId, index) => (
                      <Column
                        key={"r" + competitionId}
                        title={index + 1 + " forduló"}
                        responsive={["lg"]}
                      >
                        <Column
                          width={50}
                          title={"#"}
                          key={"placement"}
                          render={(text, record) => {
                            let tsIndex = record.tournamentScores.findIndex(
                              (ts) => ts && ts.competition === competitionId
                            );

                            return tsIndex > -1
                              ? record.tournamentScores[tsIndex]
                                  .competitionPlacement
                              : "-";
                          }}
                        />
                        <Column
                          width={50}
                          title={"P"}
                          key={"cs"}
                          render={(text, record) => {
                            let tsIndex = record.tournamentScores.findIndex(
                              (ts) => ts && ts.competition === competitionId
                            );

                            return tsIndex > -1
                              ? record.tournamentScores[tsIndex]
                                  .competitionScore
                              : "-";
                          }}
                        />
                        <Column
                          width={50}
                          title={"M"}
                          key={"misses"}
                          render={(text, record) => {
                            let tsIndex = record.tournamentScores.findIndex(
                              (ts) => ts && ts.competition === competitionId
                            );

                            return tsIndex > -1
                              ? record.tournamentScores[tsIndex]
                                  .competitionMisses || "-"
                              : "-";
                          }}
                        />
                        <Column
                          width={50}
                          title={"TS"}
                          key={"ts"}
                          render={(text, record) => {
                            let tsIndex = record.tournamentScores.findIndex(
                              (ts) => ts && ts.competition === competitionId
                            );

                            return tsIndex > -1
                              ? record.tournamentScores[tsIndex].tournamentScore
                              : "-";
                          }}
                        />
                      </Column>
                    ))}

                <Column
                  title={"Összesített"}
                  key={"sumScore"}
                  responsive={["xxl", "xl", "lg", "md", "sm"]}
                >
                  <Column
                    width={50}
                    title="TS"
                    dataIndex="tournamentScore"
                    key="ts"
                  />
                  <Column width={50} title="P" dataIndex="sumScore" key="ss" />
                  <Column
                    width={50}
                    title={"Szétl"}
                    dataIndex={"tiebreaker"}
                    key={"tiebreaker"}
                    render={(text) => text || "-"}
                  />
                </Column>
              </Table>
            </Card>
          );
        })}
    </>
  );
};
