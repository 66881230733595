import { useParams } from "react-router-dom";
import { Button, Card, InputNumber, PageHeader, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryCache } from "react-query";
import {
  getTournament,
  getTournamentEntries,
  updateEntries,
} from "../api/Tournament";
import { db } from "../utils/LocalDB";
import { groupEntries } from "../utils/CompetitionEntryUtils";
import _ from "lodash";
const { Column } = Table;

export const TournamentManager = () => {
  let { tournamentId } = useParams();
  const cache = useQueryCache();

  const [localTournamentData, setLocalTournamentData] = useState(null);
  const [localEntries, setLocalEntries] = useState([]);
  const [competitionSyncStatus, setCompetitionSyncStatus] = useState(null);

  const { data: tournament } = useQuery(
    ["tournament", parseInt(tournamentId)],
    getTournament
  );

  const { data: tournamentEntries } = useQuery(
    ["tournament-entries", parseInt(tournamentId)],
    getTournamentEntries
  );

  const [updateEntriesMutation] = useMutation(updateEntries, {
    onSuccess: () => {
      cache.invalidateQueries(["tournament-entries", parseInt(tournamentId)]);
    },
  });

  const getLocalTournament = async () => {
    let localTournament = await db.tournaments
      .where("id")
      .equals(parseInt(tournamentId))
      .toArray();

    console.log("got", localTournament);

    if (localTournament[0]) {
      setLocalTournamentData(localTournament[0]);
      getCompetitionSyncStatus(localTournament[0]);
    }
  };

  const updateEntry = async (entryId, property, value) => {
    console.log("updateentry", entryId, property, value);
    await db.tournamentEntries
      .where("id")
      .equals(entryId)
      .modify({ [property]: value });

    await getLocalEntries();
  };

  const onInputChange = async (entryId, property, value) => {
    console.log("oninputchange");
    await updateEntry(entryId, property, value);
    let local = await getLocalEntries();
    generateTournamentEntries(local);
  };

  const saveToLocal = async () => {
    db.tournaments.put({
      id: tournament.id,
      name: tournament.name,
      minRounds: tournament.minRounds,
      tiebreaking: tournament.tiebreaking.split(","),
      scoring: tournament.scoring.split(",").map((score) => parseInt(score)),
      competitions: tournament.competitions.map(
        (competition) => competition.id
      ),
      syncDate: new Date(),
    });

    console.log("remoteentries", tournamentEntries);

    await db.tournamentEntries.bulkPut(
      tournamentEntries.map((entry) => {
        return { ...entry, tournament: parseInt(tournamentId) };
      })
    );

    await db.tournamentEntries.where("id").above(999999999).delete();

    getLocalEntries();
    getLocalTournament();
  };

  const getLocalEntries = async () => {
    let localEntries = await db.tournamentEntries
      .where("tournament")
      .equals(parseInt(tournamentId))
      .toArray();

    console.log("got", localEntries);

    if (localEntries) {
      setLocalEntries(localEntries);
    }

    return localEntries;
  };

  const getCompetitionSyncStatus = async (localTournamentData) => {
    let status = await Promise.all(
      localTournamentData.competitions.map(async (competitionId) => {
        let localCompetition = await db.competitions
          .where("id")
          .equals(competitionId)
          .toArray();

        console.log("comp", competitionId, localCompetition);
        if (localCompetition[0]) {
          return {
            competitionId: competitionId,
            syncDate: localCompetition[0].syncDate,
          };
        } else {
          return { competitionId: competitionId, synced: null };
        }
      })
    );

    console.log("compsyncstatus", status);
    setCompetitionSyncStatus(status);
  };

  const generateTournamentEntries = async (localEntries) => {
    let tournamentScores = localTournamentData.scoring;
    let sumResult = localEntries || [];

    console.log("GEDNE");

    await Promise.all(
      localTournamentData.competitions.map(async (competitionId) => {
        console.log("generate comp", competitionId);
        let competitionEntries = await db.entries
          .where("competition")
          .equals(competitionId)
          .toArray();

        console.log("compentries", competitionEntries);

        let categories = {};

        //categorize entries
        competitionEntries.forEach((entry) => {
          let categoryId = `${entry.category.id}_${entry.ageGroup.ageGroup.id}_${entry.gender}`;
          if (!categories[categoryId]) {
            categories[categoryId] = [];
          }

          categories[categoryId].push({
            ...entry,
            ageGroup: entry.ageGroup.ageGroup,
          });
        });

        console.log("categories", categories);

        let categoryIdList = Object.keys(categories);

        categoryIdList.forEach((categoryId) => {
          console.log("generate category", categoryId);
          categories[categoryId].sort((a, b) => {
            return a.placement - b.placement;
          });

          categories[categoryId].forEach((e, index) => {
            let sumResultEntry = sumResult.filter(
              (resultEntry) =>
                resultEntry.name === e.name &&
                resultEntry.gender === e.gender &&
                resultEntry.ageGroup.id === e.ageGroup.id &&
                resultEntry.category.id === e.category.id
            );
            if (sumResultEntry.length > 0) {
              let tsIndex = sumResultEntry[0].tournamentScores.findIndex(
                (ts) => ts && ts.competition === competitionId
              );
              sumResultEntry[0].tournamentScores[
                tsIndex > -1
                  ? tsIndex
                  : sumResultEntry[0].tournamentScores.length + 1
              ] = {
                tournamentEntry: sumResultEntry[0].id,
                competition: competitionId,
                competitionScore: e.score,
                competitionMisses: e.s0,
                competitionPlacement: e.placement,
                tournamentScore: tournamentScores[index],
              };
            } else {
              let tournamentEntryId =
                Math.floor(Math.random() * 1000000) + 1000000000;
              let tempSumResultEntry = {
                id: tournamentEntryId,
                tournament: parseInt(tournamentId),
                name: e.name,
                gender: e.gender,
                ageGroup: e.ageGroup,
                category: e.category,
                club: e.club,
                tournamentScores: [
                  {
                    tournamentEntry: tournamentEntryId,
                    competition: competitionId,
                    competitionScore: e.score,
                    competitionMisses: e.s0,
                    competitionPlacement: e.placement,
                    tournamentScore: tournamentScores[index],
                  },
                ],
              };

              sumResult.push(tempSumResultEntry);
            }
          });
        });
      })
    );

    console.log("sumres", sumResult);

    sumResult.forEach((entry) => {
      entry.tie = false;
      entry.tournamentScores.sort((a, b) => {
        if (a.tournamentScore > b.tournamentScore) {
          return -1;
        }
        if (a.tournamentScore < b.tournamentScore) {
          return 1;
        }
        if (a.competitionScore > b.competitionScore) {
          return -1;
        }
        if (a.competitionScore < b.competitionScore) {
          return 1;
        }

        return 0;
      });

      console.log("tsorder", entry.tournamentScores);

      console.log("minr", localTournamentData.minRounds);

      if (entry.tournamentScores.length >= localTournamentData.minRounds) {
        entry.tournamentScore = entry.tournamentScores
          .slice(0, localTournamentData.minRounds)
          .reduce((prev, curr) => prev + curr.tournamentScore, 0);

        entry.sumScore = entry.tournamentScores
          .slice(0, localTournamentData.minRounds)
          .reduce((prev, curr) => prev + curr.competitionScore, 0);

        console.log("ent", entry);
      }
    });

    let categories = {};
    sumResult.forEach((entry) => {
      let categoryId = `${entry.category.id}_${entry.ageGroup.id}_${entry.gender}`;
      if (!categories[categoryId]) {
        categories[categoryId] = [];
      }

      categories[categoryId].push(entry);
    });

    let categoryIdList = Object.keys(categories);

    categoryIdList.forEach((categoryId) => {
      categories[categoryId].sort((a, b) => {
        if (a.tournamentScore > b.tournamentScore) {
          return 1;
        }
        if (b.tournamentScore > a.tournamentScore) {
          return -1;
        }

        for (let i = 0; i < localTournamentData.tiebreaking.length; i++) {
          let tiebreakerValue = localTournamentData.tiebreaking[i];

          if (tiebreakerValue.startsWith("R")) {
            let roundIndex = parseInt(tiebreakerValue.split("_")[0][1]) - 1;
            let competitionId = localTournamentData.competitions[roundIndex];
            let scoreValue = tiebreakerValue.split("_")[1];

            let aTournamentScores =
              a.tournamentScores[
                a.tournamentScores.findIndex(
                  (ts) => ts && ts.competition === competitionId
                )
              ];
            let bTournamentScores =
              b.tournamentScores[
                b.tournamentScores.findIndex(
                  (ts) => ts && ts.competition === competitionId
                )
              ];

            if (!aTournamentScores && bTournamentScores) {
              return -1;
            } else if (!bTournamentScores && aTournamentScores) {
              return 1;
            } else if (aTournamentScores && bTournamentScores) {
              if (scoreValue === "S0") {
                if (aTournamentScores.s0 < bTournamentScores.s0) {
                  return 1;
                }
                if (aTournamentScores.s0 > bTournamentScores.s0) {
                  return -1;
                }
              } else {
                if (
                  aTournamentScores[scoreValue] > bTournamentScores[scoreValue]
                ) {
                  return 1;
                }
                if (
                  aTournamentScores[scoreValue] < bTournamentScores[scoreValue]
                ) {
                  return -1;
                }
              }
            }
          } else if (tiebreakerValue === "T") {
            if (a.tiebreaker > b.tiebreaker) {
              return 1;
            }
            if (a.tiebreaker < b.tiebreaker) {
              return -1;
            }
          } else if (tiebreakerValue === "S") {
            if (a.sumScore > b.sumScore) {
              return 1;
            }
            if (a.sumScore < b.sumScore) {
              return -1;
            }
          }
        }

        a.tie = true;
        b.tie = true;

        return 0;
      });

      categories[categoryId].reverse();

      categories[categoryId]
        .filter(
          (entry) =>
            entry.tournamentScores.length >= localTournamentData.minRounds
        )
        .forEach((entry, index) => {
          entry.placement = index + 1;
        });
    });

    await db.tournamentEntries.bulkPut(sumResult);

    getLocalEntries();
  };

  const onMountEffect = () => {
    getLocalEntries();
    getLocalTournament();
  };

  useEffect(onMountEffect, []);

  console.log("localentries", localEntries);

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title={localTournamentData && localTournamentData.name}
        subTitle={
          localTournamentData
            ? "Szinkronizálva: " + localTournamentData.syncDate.toISOString()
            : ""
        }
        extra={[
          <Button key={"sync"} onClick={() => saveToLocal()}>
            Sync
          </Button>,
          <Button
            key={"update"}
            onClick={() => {
              updateEntriesMutation({
                tournamentId: localTournamentData.id,
                entries: localEntries,
              });
            }}
          >
            Update
          </Button>,
          <Button
            key={"gen"}
            onClick={() => {
              generateTournamentEntries(localEntries);
            }}
          >
            Generate
          </Button>,
        ]}
      />
      {competitionSyncStatus &&
        competitionSyncStatus.map(({ competitionId, syncDate }) => (
          <div key={competitionId}>
            {competitionId} - {syncDate && syncDate.toString()}
          </div>
        ))}

      {Object.entries(groupEntries(localEntries)).map(([key, value]) => {
        value.entries.sort(
          (a, b) => (a.placement || 999) - (b.placement || 999)
        );
        return (
          <Card
            key={key}
            title={`${value.category.name} ${value.ageGroup.name} ${value.gender}`}
            style={{ marginBottom: "16px" }}
          >
            <Table
              bordered
              dataSource={value.entries}
              pagination={false}
              size={"small"}
              rowKey={(row) => row.id}
              rowClassName={(record) =>
                record.tie ? "tie" : "placement-" + record.placement
              }
            >
              <Column title="Név" dataIndex="name" key="name" />
              <Column
                title="Egyesület"
                dataIndex={["club", "name"]}
                key="club"
              />

              {localTournamentData &&
                localTournamentData.competitions.map((competitionId, index) => (
                  <Column
                    key={"r" + competitionId}
                    title={index + 1 + " forduló"}
                  >
                    <Column
                      title={"#"}
                      key={"placement"}
                      render={(text, record) => {
                        let tsIndex = record.tournamentScores.findIndex(
                          (ts) => ts && ts.competition === competitionId
                        );

                        return tsIndex > -1
                          ? record.tournamentScores[tsIndex]
                              .competitionPlacement
                          : "-";
                      }}
                    />
                    <Column
                      title={"P"}
                      key={"cs"}
                      render={(text, record) => {
                        let tsIndex = record.tournamentScores.findIndex(
                          (ts) => ts && ts.competition === competitionId
                        );

                        return tsIndex > -1
                          ? record.tournamentScores[tsIndex].competitionScore
                          : "-";
                      }}
                    />
                    <Column
                      title={"M"}
                      key={"misses"}
                      render={(text, record) => {
                        let tsIndex = record.tournamentScores.findIndex(
                          (ts) => ts && ts.competition === competitionId
                        );

                        return tsIndex > -1
                          ? record.tournamentScores[tsIndex]
                              .competitionMisses || "-"
                          : "-";
                      }}
                    />
                    <Column
                      title={"TS"}
                      key={"ts"}
                      render={(text, record) => {
                        let tsIndex = record.tournamentScores.findIndex(
                          (ts) => ts && ts.competition === competitionId
                        );

                        return tsIndex > -1
                          ? record.tournamentScores[tsIndex].tournamentScore
                          : "-";
                      }}
                    />
                  </Column>
                ))}

              <Column title={"Összesített"} key={"sumScore"}>
                <Column title="#" dataIndex="placement" key="placement" />
                <Column title="TS" dataIndex="tournamentScore" key="ts" />
                <Column title="P" dataIndex="sumScore" key="ss" />
                <Column
                  title={"Tiebreaker"}
                  dataIndex={"tiebreaker"}
                  key={"tiebreaker"}
                  render={(colValue, entry) => (
                    <InputNumber
                      value={colValue}
                      onChange={_.debounce(
                        (value) => onInputChange(entry.id, "tiebreaker", value),
                        300
                      )}
                    />
                  )}
                />
              </Column>
            </Table>
          </Card>
        );
      })}
    </>
  );
};
