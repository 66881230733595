import React from "react";
import { Menu, Grid } from "antd";
import { useQuery } from "react-query";
import { getTournaments } from "../api/Tournament";
import { Link } from "react-router-dom";
const SubMenu = Menu.SubMenu;

const { useBreakpoint } = Grid;

export const LeftMenu = ({ onClose }) => {
  const { lg } = useBreakpoint();

  const { data: tournaments } = useQuery("tournaments", getTournaments);

  return (
    <Menu selectable={false} theme={"dark"} mode={lg ? "horizontal" : "inline"}>
      <Menu.Item key="2">
        <Link
          id="competitions-menu-button"
          to="/competitions"
          onClick={onClose}
        >
          Versenyek
        </Link>
      </Menu.Item>
      <SubMenu key="3" title="Kupák">
        {tournaments &&
          tournaments.map((tournament) => (
            <Menu.Item key={tournament.id}>
              <Link
                to={`/tournament/${tournament.id}/${tournament.slug}`}
                onClick={onClose}
              >
                {tournament.name}
              </Link>
            </Menu.Item>
          ))}
      </SubMenu>
      <Menu.Item key="4">
        <Link to="/calendar" onClick={onClose}>
          Versenynaptáram
        </Link>
      </Menu.Item>
    </Menu>
  );
};
