import React from "react";
import { Tag } from "antd";

export const AgeGroupTag = ({ ageGroup }) => {
  return (
    <>
      {{
        Gyerek: <Tag color={"green"}>Gyerek</Tag>,
        Felnőtt: <Tag color={"geekblue"}>Felnőtt</Tag>,
        Veterán: <Tag color={"red"}>Veterán</Tag>,
        Ifi: <Tag color={"cyan"}>Ifi</Tag>,
        Mini: <Tag color={"magenta"}>Mini</Tag>,
        Kadet: <Tag color={"purple"}>Kadet</Tag>,
        "65+": <Tag color={"gold"}>65+</Tag>,
      }[ageGroup] || <Tag color={"blue"}>{ageGroup}</Tag>}
    </>
  );
};
