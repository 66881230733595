import React from "react";
import { Tag } from "antd";

export const CategoryTag = ({ category }) => {
  return (
    <>
      {{
        CRB: <Tag color={"red"}>CRB</Tag>,
        CU: <Tag color={"orange"}>CU</Tag>,
        HU: <Tag color={"gold"}>HU</Tag>,
        CB: <Tag color={"volcano"}>CB</Tag>,
        OL: <Tag color={"green"}>OL</Tag>,
        BB: <Tag color={"cyan"}>BB</Tag>,
        TRRB: <Tag color={"blue"}>TRRB</Tag>,
        TRLB: <Tag color={"magenta"}>TRLB</Tag>,
        PBHB: <Tag color={"purple"}>PBHB</Tag>,
      }[category] || <Tag color={"blue"}>{category}</Tag>}
    </>
  );
};
