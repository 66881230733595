import React from "react";
import PropTypes from "prop-types";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { Spin } from "antd";

import marker_FIELD from "./markers/marker_field.png";
import marker_FUN from "./markers/marker_fun.png";
import marker_HISTORICAL from "./markers/marker_historical.png";
import marker_HOME from "./markers/marker_home.png";
import marker_OTHER from "./markers/marker_other.png";
import marker_TARGET from "./markers/marker_target.png";
import marker_THREED from "./markers/marker_threed.png";

const containerStyle = {
  width: "100%",
  height: "540px",
};

const defaultCenter = {
  lat: 47.312967,
  lng: 19.444317,
};

const markers = {
  marker_FIELD,
  marker_FUN,
  marker_HISTORICAL,
  marker_HOME,
  marker_OTHER,
  marker_TARGET,
  marker_THREED,
};

const renderMap = (competitions, animatedCompetition, center, zoom) => {

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center || defaultCenter}
      zoom={zoom || 7}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {competitions.map((competition) => (
        <Marker
          position={{ lat: competition.lat, lng: competition.lng }}
          icon={markers["marker_" + competition.type]}
          key={competition.id}
          animation={
            animatedCompetition === competition.id
              ? window.google.maps.Animation.BOUNCE
              : null
          }
        />
      ))}
    </GoogleMap>
  );
};

function MapComponent({ competitions, animatedCompetition, center, zoom }) {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });

  if (loadError) {
    return <div>Map cannot be loaded.</div>;
  }

  return isLoaded ? (
    renderMap(competitions, animatedCompetition, center, zoom)
  ) : (
    <Spin />
  );
}

MapComponent.propTypes = {
  competitions: PropTypes.array,
  animatedCompetition: PropTypes.number,
};

export const Map = React.memo(MapComponent);
