import React from "react";
import { Card, Table, Grid } from "antd";
import "./PlacementTab.less";
import { groupEntries } from "../../utils/CompetitionEntryUtils";

const { Column } = Table;
const { useBreakpoint } = Grid;

export const PlacementTab = ({ localCompetitionData, localEntries }) => {
  const { xl } = useBreakpoint();

  return (
    <>
      {Object.entries(groupEntries(localEntries)).map(([key, value]) => (
        <Card
          key={key}
          title={`${value.category.name} ${value.ageGroup.ageGroup.name} ${
            value.gender === "MALE" ? "Férfi" : "Nő"
          }`}
          style={{ marginBottom: "16px" }}
          bodyStyle={{ padding: xl ? "16px" : 0 }}
        >
          <Table
            dataSource={value.entries}
            pagination={false}
            size={"small"}
            rowClassName={(record) =>
              record.tie ? "tie" : "placement-" + record.placement
            }
          >
            <Column
              title="#"
              dataIndex="placement"
              key="placement"
              width={30}
            />
            <Column title="Név" dataIndex="name" key="name" />
            <Column title="Egyesület" dataIndex={["club", "name"]} key="club" />
            {localCompetitionData &&
              [...localCompetitionData.scoring]
                .reverse()
                .map((scoreProperty) => (
                  <Column
                    title={scoreProperty}
                    dataIndex={"s" + scoreProperty}
                    key={"s" + scoreProperty}
                    responsive={["sm"]}
                  />
                ))}
            <Column title={"Score"} dataIndex={"score"} key={"score"} />
          </Table>
        </Card>
      ))}
    </>
  );
};
