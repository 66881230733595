import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  getManagedCompetition,
  getManagedCompetitionEntries,
} from "../api/Competition";
import { useParams } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import { Col, Row, Skeleton } from "antd";
import "./Teams.less";
import { EntriesCol } from "./EntriesCol";
import { TeamsCol } from "./TeamsCol";
import { TeamsPageHeader } from "./TeamsPageHeader";

export const Teams = () => {
  let { competitionId } = useParams();
  const { data: competition, isLoading: isLoadingCompetition } = useQuery(
    ["managed-competition", parseInt(competitionId)],
    getManagedCompetition
  );
  const { isLoading: isLoadingEntries } = useQuery(
    ["managed-competition-entries", parseInt(competitionId)],
    getManagedCompetitionEntries,
    {
      onSuccess: (data) => {
        let entryMap = {};
        [...Array(21).keys()].map((teamNum) => (entryMap[teamNum] = []));
        data.forEach((entry) => {
          if (!entry.groupId) {
            let newGroupId = Math.floor(Math.random() * 1000000000);
            entry.groupId = newGroupId;
            let newGroup = {
              id: newGroupId,
              entries: [entry],
              team: 0,
            };
            entryMap[0].push(newGroup);
          } else {
            let placed = false;
            Object.keys(entryMap).forEach((team) => {
              let containingGroup = entryMap[team].find(
                (group) => group.id === entry.groupId
              );

              if (containingGroup) {
                containingGroup.entries.splice(entry.teamIndex, 0, entry);
                placed = true;
              }
            });

            if (!placed) {
              let newGroup = {
                id: entry.groupId,
                entries: [entry],
                team: entry.team,
              };
              entryMap[entry.team].push(newGroup);
            }
          }
        });

        setTeamIndexes(entryMap);
        setEntries(entryMap);
      },
    }
  );

  const [entries, setEntries] = useState({});

  const setTeamIndexes = (entryMap) => {
    Object.keys(entryMap).forEach((teamNumber) => {
      let teamIndex = 0;
      entryMap[teamNumber].forEach((group, index) => {
        group.groupIndex = index;
        group.entries.forEach((e) => {
          e.team = teamNumber;
          e.teamIndex = teamIndex;
          teamIndex++;
        });
      });
    });
  };

  const changeTeamOfGroup = (fromTeam, fromIndex, toTeam, toIndex) => {
    let tempEntriesMap = { ...entries };
    let newEntry = {
      ...tempEntriesMap[fromTeam][fromIndex],
      team: toTeam,
      groupIndex: toIndex,
    };

    newEntry.entries.forEach((entry) => (entry.team = toTeam));

    tempEntriesMap[fromTeam].splice(fromIndex, 1);
    tempEntriesMap[toTeam].splice(toIndex, 0, newEntry);

    setTeamIndexes(tempEntriesMap);

    setEntries(tempEntriesMap);
  };

  const putEntryIntoGroup = (entryIndex, fromTeam, groupId, toTeam) => {
    console.log(entryIndex, fromTeam, groupId, toTeam);

    let tempEntriesMap = { ...entries };

    let movedEntries = tempEntriesMap[fromTeam][entryIndex].entries;
    movedEntries.forEach((entry) => (entry.groupId = groupId));

    tempEntriesMap[toTeam]
      .find((group) => group.id == groupId)
      .entries.push(...movedEntries);

    tempEntriesMap[fromTeam].splice(entryIndex, 1);

    setTeamIndexes(tempEntriesMap);

    setEntries(tempEntriesMap);
  };

  const unlockGroup = (group) => {
    console.log("unlock group", group);

    let tempEntriesMap = { ...entries };

    let newGroups = group.entries.map((entry) => {
      let newGroupId = Math.floor(Math.random() * 1000000000);
      entry.groupId = newGroupId;
      return {
        id: newGroupId,
        entries: [entry],
        team: group.team,
      };
    });

    tempEntriesMap[group.team].splice(group.groupIndex, 1);

    tempEntriesMap[group.team].push(...newGroups);

    setTeamIndexes(tempEntriesMap);

    setEntries(tempEntriesMap);
  };

  const handleDragEnd = (event) => {
    console.log("drop", event);
    if (event.combine) {
      let fromTeam = event.source.droppableId.split("-")[1];
      let groupId = event.combine.draggableId;
      let toTeam = event.combine.droppableId.split("-")[1];

      putEntryIntoGroup(
        event.source.index,
        fromTeam,
        groupId.split("-")[1],
        toTeam
      );
    } else {
      if (!event.destination) {
        return;
      }

      let fromTeam = event.source.droppableId.split("-")[1];
      let toTeam = event.destination.droppableId.split("-")[1];
      changeTeamOfGroup(
        parseInt(fromTeam),
        event.source.index,
        parseInt(toTeam),
        event.destination.index
      );
    }
  };

  return (
    <div className={"teams-page"}>
      <Skeleton
        title={false}
        loading={isLoadingEntries || isLoadingCompetition}
        paragraph={5}
        active
      />
      {competition && (
        <>
          <TeamsPageHeader competition={competition} entries={entries} />

          <DragDropContext
            onDragEnd={(event) => {
              handleDragEnd(event);
            }}
          >
            <Row gutter={[8, 8]}>
              <Col xs={12} lg={16} xxl={18}>
                <TeamsCol
                  entries={entries}
                  unlockGroup={unlockGroup}
                  setEntries={setEntries}
                  setTeamIndexes={setTeamIndexes}
                />
              </Col>
              <Col xs={12} lg={8} xxl={6}>
                <EntriesCol entries={entries} unlockGroup={unlockGroup} />
              </Col>
            </Row>
          </DragDropContext>
        </>
      )}
    </div>
  );
};
