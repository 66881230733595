import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const register = async (user) => {
  const { data } = await axios.post(`${API_URL}/auth/register`, user);
  return data;
};

export const login = async (user) => {
  const { data } = await axios.post(`${API_URL}/auth/login`, user);
  return data;
};
