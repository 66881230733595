import React, { useState } from "react";
import { useMutation, useQuery, useQueryCache } from "react-query";
import {
  deleteEntry,
  downloadExcel,
  getManagedCompetition,
  getManagedCompetitionEntries,
  managedUpdateEntry,
} from "../api/Competition";
import { useParams } from "react-router-dom";
import { Button, Form, PageHeader, Popconfirm, Space, Table } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import { getClubs } from "../api/Club";
import { EditableCell } from "./EditableCell";
import { useHistory } from "react-router";
import { GenderTag } from "../tag/GenderTag";
import { CategoryTag } from "../tag/CategoryTag";
import { AgeGroupTag } from "../tag/AgeGroupTag";

export const ManageCompetition = () => {
  let history = useHistory();
  let { competitionId } = useParams();

  const { data: competition } = useQuery(
    ["managed-competition", parseInt(competitionId)],
    getManagedCompetition
  );

  const { data: entries, isFetching: isFetchingEntries } = useQuery(
    ["managed-competition-entries", parseInt(competitionId)],
    getManagedCompetitionEntries
  );
  const cache = useQueryCache();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const { data: clubs } = useQuery("clubs", getClubs);

  const [updateEntryMutate, { isLoading: isLoadingUpdateEntry }] = useMutation(
    managedUpdateEntry,
    {
      onSuccess: () => {
        Swal.fire({
          title: "Sikeres módosítás!",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        cache.invalidateQueries([
          "managed-competition-entries",
          parseInt(competitionId),
        ]);
      },
    }
  );

  const [deleteEntryMutate, { isLoading: isLoadingDeleteEntry }] = useMutation(
    deleteEntry,
    {
      onSuccess: () => {
        Swal.fire({
          title: "A nevezés sikeresen visszavonva!",
          icon: "success",
          timer: 1000,
        });
        cache.invalidateQueries([
          "managed-competition-entries",
          parseInt(competitionId),
        ]);
      },
    }
  );

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      category: record.category.id,
      ageGroup: record.ageGroup.id,
      club: record.club.id,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id) => {
    try {
      await form.validateFields();

      const index = entries.findIndex((item) => id === item.id);
      const oldData = entries[index];

      let formValues = form.getFieldsValue();
      let updatedEntry = {
        ...oldData,
        ...formValues,
        category: competition.categories.find(
          (category) => category.id === formValues.category
        ),
        ageGroup: competition.ageGroups.find(
          (ageGroup) => ageGroup.id === formValues.ageGroup
        ),
        club: clubs.find((club) => club.id === formValues.club),
      };
      console.log("updatedentry", updatedEntry);
      await updateEntryMutate({
        competitionId: parseInt(competitionId),
        entry: updatedEntry,
      });
      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const categoryTag = (text) => <CategoryTag category={text} />;
  const ageGroupTag = (text) => <AgeGroupTag ageGroup={text} />;
  const genderTag = (text) => <GenderTag gender={text} />;
  const actionsComp = (_, record) => {
    const editable = isEditing(record);
    return editable ? (
      <Space>
        <Button onClick={cancel}>
          <CloseOutlined />
        </Button>
        <Popconfirm
          title="Biztos hogy módosítod a nevezésedet?"
          onConfirm={() => save(record.id)}
        >
          <Button>
            <CheckOutlined />
          </Button>
        </Popconfirm>
      </Space>
    ) : (
      <Space>
        <Button disabled={editingKey !== ""} onClick={() => edit(record)}>
          <EditOutlined />
        </Button>
        <Popconfirm
          title="Biztos hogy visszavonod a nevezésedet?"
          onConfirm={() => deleteEntryMutate(record.id)}
        >
          <Button danger disabled={editingKey !== ""}>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </Space>
    );
  };

  const columns = [
    {
      title: "Név",
      dataIndex: "name",
      width: 220,
    },
    {
      title: "V.eng.",
      dataIndex: "permitNumber",
      width: 80,
    },
    {
      title: "Kateg.",
      dataIndex: ["category", "name"],
      editable: true,
      competition: competition,
      render: categoryTag,
      width: 100,
    },
    {
      title: "Korosztály",
      dataIndex: ["ageGroup", "ageGroup", "name"],
      editable: true,
      competition: competition,
      render: ageGroupTag,
      width: 130,
    },
    {
      title: "Nem",
      dataIndex: "gender",
      editable: true,
      render: genderTag,
      width: 90,
    },
    {
      title: "Egyesület",
      dataIndex: ["club", "name"],
      editable: true,
      ellipsis: true,
      width: 190,
    },
    {
      title: "Ebéd",
      dataIndex: "lunch",
      editable: true,
      width: 85,
    },
    {
      title: "Email",
      dataIndex: "email",
      editable: false,
      width: 220,
    },
    {
      title: "Megjegyzés",
      dataIndex: "comment",
      editable: true,
    },
    {
      title: "Műveletek",
      width: 130,
      dataIndex: "operation",
      fixed: "right",
      render: actionsComp,
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        clubs: clubs,
        competition: col.competition,
      }),
    };
  });

  return (
    <>
      {competition && (
        <>
          <PageHeader
            className="site-page-header"
            onBack={() => history.push("/manager-dashboard")}
            title={competition.name}
            extra={[
              <Button
                key="2"
                type="primary"
                onClick={() => downloadExcel(competition.id)}
              >
                Letöltés excel formátumban
              </Button>,
            ]}
          />
          <Form form={form} component={false}>
            <Table
              scroll={{ x: 1700 }}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={entries}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={false}
              loading={
                isLoadingUpdateEntry ||
                isFetchingEntries ||
                isLoadingDeleteEntry
              }
            />
          </Form>
        </>
      )}
    </>
  );
};
