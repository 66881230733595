import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { Card } from "antd";
import { Group } from "./Group";

export const EntriesCol = ({ entries, unlockGroup }) => {
  return (
    <Droppable droppableId={`team-0`} key={"team-0"} isCombineEnabled>
      {(provided) => {
        return (
          <Card title={"Nevezők"} key={"entries"}>
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {entries[0] &&
                entries[0].map((group) => (
                  <Group
                    key={group.id}
                    group={group}
                    unlockGroup={unlockGroup}
                  />
                ))}
              {provided.placeholder}
            </div>
          </Card>
        );
      }}
    </Droppable>
  );
};
