import { Card, List, Skeleton } from "antd";
import React from "react";

import "./EntriesList.less";
import { GenderTag } from "../tag/GenderTag";
import { CategoryTag } from "../tag/CategoryTag";
import { AgeGroupTag } from "../tag/AgeGroupTag";

import _ from "lodash";

export const TeamedEntriesList = ({ entries, isFetchingEntries }) => {
  const sortedEntries = [...entries];
  sortedEntries.sort((a, b) => a.teamIndex - b.teamIndex);

  const groupedEntries = _.groupBy(sortedEntries, "team");

  return (
    <div className={"competition-entries-list"}>
      {Object.keys(groupedEntries)
        .sort()
        .map((team) => (
          <Card
            key={team}
            title={`${team}. csapat`}
            headStyle={{ textAlign: "center" }}
            bodyStyle={{ padding: "4px 0px 4px 8px" }}
          >
            <List
              key={team}
              dataSource={groupedEntries[team]}
              loading={isFetchingEntries}
              renderItem={(entry) => (
                <List.Item>
                  <Skeleton
                    avatar
                    title={false}
                    loading={isFetchingEntries}
                    active
                  >
                    <List.Item.Meta
                      title={
                        <div className={"entry"}>
                          <div className={"entry-left"}>
                            <div className={"entry-name"}>
                              <div>{entry.name}</div>
                              <div className={"entry-club"}>
                                {entry.club.name}
                              </div>
                            </div>
                          </div>
                          <div className={"entry-right"}>
                            <div>
                              <CategoryTag category={entry.category.name} />
                            </div>
                            <div>
                              <AgeGroupTag
                                ageGroup={entry.ageGroup.ageGroup.name}
                              />
                            </div>
                            <div>
                              <GenderTag gender={entry.gender} />
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </Card>
        ))}
    </div>
  );
};
