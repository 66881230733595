import { Select } from "antd";
import React from "react";
import PropTypes from "prop-types";

const { Option } = Select;

export const AgeGroupInput = ({ ageGroups, ...restProps }) => {
  return (
    <Select {...restProps}>
      {ageGroups
        .sort((a, b) => a.fromAge - b.fromAge)
        .map((ageGroup) => (
          <Option key={ageGroup.id} value={ageGroup.id}>
            {ageGroup.ageGroup.name} ({ageGroup.fromAge}-{ageGroup.toAge})
          </Option>
        ))}
    </Select>
  );
};

AgeGroupInput.propTypes = {
  ageGroups: PropTypes.array,
};
