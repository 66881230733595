import React from "react";
import "./Home.less";
import { Card, Row, Col } from "antd";
import { useQuery } from "react-query";
import { getNews } from "../api/News";
import moment from "moment";
import DOMPurify from "dompurify";
import { UpcomingCompetitions } from "./UpcomingCompetitions";

export const Home = () => {
  const { data: news } = useQuery(["news"], getNews);

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Card title={"Versenyek a héten"} bodyStyle={{ padding: "0" }}>
                <UpcomingCompetitions />
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Card title={"Legfrissebb eredmények"}>
                Még nincs feltöltött eredmény.
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={0} lg={12}>
          <Card title={"Hírek"}>
            {news &&
              news.map((news) => (
                <Card
                  key={news.id}
                  type="inner"
                  title={news.title}
                  extra={moment(news.postDate).format("YYYY.MM.DD. HH:MM")}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(news.content),
                    }}
                  ></div>
                </Card>
              ))}
          </Card>
        </Col>
        <Col xs={24} lg={0}>
          {news &&
            news.map((news) => (
              <Card
                key={news.id}
                type="inner"
                title={news.title}
                extra={moment(news.postDate).format("YYYY.MM.DD. HH:MM")}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(news.content),
                  }}
                ></div>
              </Card>
            ))}
        </Col>
      </Row>
    </div>
  );
};
