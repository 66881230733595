import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  DatePicker,
} from "antd";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { getClubs } from "../api/Club";
import { insertProfile } from "../api/Profile";
import Swal from "sweetalert2";
const { Option } = Select;

export const CreateProfileModal = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const cache = useQueryCache();

  const { data: clubs } = useQuery("clubs", getClubs);

  const [insertProfileMutate, { isLoading }] = useMutation(insertProfile);

  return (
    <Modal
      visible={visible}
      title="Új profil létrehozása"
      footer={[
        <Button key="back" onClick={onCancel}>
          Mégse
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={() => {
            form
              .validateFields()
              .then(async (values) => {
                let profileData = { ...values };
                profileData.club = clubs.find(
                  (club) => club.id === profileData.club
                );
                let mutateResult = await insertProfileMutate(profileData);
                await cache.invalidateQueries(["userData"]);
                let newProfile = mutateResult.data;
                Swal.fire({
                  title: "Sikeres profil létrehozás!",
                  icon: "success",
                  timer: 2000,
                  showConfirmButton: false,
                });
                form.resetFields();
                console.log("newprof", newProfile);
                onCreate(newProfile);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          Létrehozás
        </Button>,
      ]}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        layout="horizontal"
        name="form_in_modal"
        initialValues={{
          name: "",
          permitNumber: "",
          birthday: "",
          gender: "",
          club: "",
        }}
      >
        <Form.Item
          name="name"
          label="Név"
          rules={[
            {
              required: true,
              message: "Add meg a nevet!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Versenyeng."
          name="permitNumber"
          rules={[
            {
              required: true,
              message: "Add meg a versenyengedély számot!",
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="birthday"
          label="Születési dátum"
          rules={[
            {
              required: true,
              message: "Add meg a születési dátumot!",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Nem"
          name="gender"
          rules={[
            {
              required: true,
              message: "Válaszd ki a nemet!",
            },
          ]}
        >
          <Select>
            <Option value="MALE">Férfi</Option>
            <Option value="FEMALE">Nő</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Egyesület"
          name="club"
          rules={[
            {
              required: true,
              message: "Válaszd ki az egyesületet!",
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={999}>Egyéb</Option>
            {(clubs || []).map((club) => (
              <Option key={club.id} value={club.id}>
                {club.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CreateProfileModal.propTypes = {
  visible: PropTypes.bool,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
};
