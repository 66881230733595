import React from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from "react-query";
import {
  getCompetition,
  getEntries,
  getOwnEntries,
  insertEntry,
} from "../api/Competition";
import {
  PageHeader,
  Form,
  Card,
  Skeleton,
  Row,
  Col,
  BackTop,
  Tabs,
  Space,
  Divider,
  Button,
  Grid,
  Typography,
} from "antd";
import { CarryOutOutlined, CalendarOutlined } from "@ant-design/icons";
import moment from "moment";

import "./Competition.less";
import { getClubs } from "../api/Club";
import { EntryEditor } from "../entryEditor/EntryEditor";
import { PlacementTab } from "../manager/placementTab/PlacementTab";
import { ApplicationForm } from "./ApplicationForm";
import { EntriesTable } from "./EntriesTable";
import { Description } from "./Description";
import { EntriesList } from "./EntriesList";
import { useHistory } from "react-router";
import { useAuth } from "../auth/Auth";
import {
  addToCalendar,
  getCalendar,
  getUserData,
  removeFromCalendar,
} from "../api/User";
import Swal from "sweetalert2";
import { resolveCompetitionType } from "../utils/CompetitionType";
import { CategoriesBarChart } from "./CategoriesBarChart";
import { TeamedEntriesList } from "./TeamedEntriesList";
import _ from "lodash";

const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

export const Competition = () => {
  const { xl } = useBreakpoint();
  const auth = useAuth();
  let { competitionId } = useParams();
  let history = useHistory();
  let competitionIdNumber = parseInt(competitionId);

  const cache = useQueryCache();

  const { data: userData } = useQuery(["userData"], getUserData, {
    enabled: auth.user,
  });

  const { data: competition, isFetching: isFetchingCompetition } = useQuery(
    ["competition", competitionIdNumber],
    getCompetition
  );
  const { data: ownEntries } = useQuery(
    ["ownentries", competitionIdNumber],
    getOwnEntries,
    { enabled: auth.user }
  );
  const { data: entries, isFetching: isFetchingEntries } = useQuery(
    ["entries", competitionIdNumber],
    getEntries
  );
  const { data: clubs } = useQuery("clubs", getClubs);
  const [mutate, { isLoading: isLoadingInsertEntry }] = useMutation(
    insertEntry,
    {
      onSuccess: () => {
        cache.invalidateQueries(["entries", competitionIdNumber]);
        cache.invalidateQueries(["ownentries", competitionIdNumber]);
        form.resetFields();
      },
    }
  );

  const { data: calendar } = useQuery("calendar", getCalendar, {
    enabled: auth.user,
  });

  const [addToCalendarMutate] = useMutation(addToCalendar, {
    onSuccess: () => {
      cache.invalidateQueries(["calendar"]);
      Swal.fire({
        title: "Hozzáadva a naptáradhoz!",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
    },
  });

  const [removeFromCalendarMutate] = useMutation(removeFromCalendar, {
    onSuccess: () => {
      cache.invalidateQueries(["calendar"]);
      Swal.fire({
        title: "Eltávolítva a naptáradból!",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
    },
  });

  const [form] = Form.useForm();

  const onSubmitEntry = async (entryData) => {
    console.log("onsubmitentry", entryData);
    let entry = { ...entryData };
    entry.category = competition.categories.find(
      (category) => category.id === entryData.category
    );
    entry.ageGroup = competition.ageGroups.find(
      (ageGroup) => ageGroup.id === entryData.ageGroup
    );
    entry.club = clubs.find((club) => club.id === entryData.club);

    if (entryData.profile) {
      entry.profile = userData.profiles.find(
        (profile) => profile.id === entryData.profile
      );
      entry.name = entry.profile.name;
      entry.email = userData.email;
    }

    try {
      await mutate({ competitionId: competitionIdNumber, entry });
      Swal.fire({
        title: "Sikeres nevezés!",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getCalendarButton = () => {
    if (auth.user && calendar) {
      if (
        !calendar.find(
          (calendarCompetition) => calendarCompetition.id === competition.id
        )
      ) {
        return (
          <Button
            key={"add"}
            onClick={() => addToCalendarMutate(competition.id)}
          >
            {xl ? "Felvétel a versenynaptáradba" : <CarryOutOutlined />}
          </Button>
        );
      } else {
        return (
          <Button
            key={"remove"}
            danger
            onClick={() => removeFromCalendarMutate(competition.id)}
          >
            {xl ? "Eltávolítás a versenynaptáradból" : <CalendarOutlined />}
          </Button>
        );
      }
    }
  };

  return (
    <>
      {isFetchingCompetition && <Skeleton active paragraph={{ rows: 10 }} />}
      {!isFetchingCompetition && (
        <div className={"competition-page"}>
          <PageHeader
            className="site-page-header"
            onBack={() => history.push("/competitions")}
            title={competition.name}
            subTitle={
              <Col xs={0} md={24}>
                <Space split={<Divider type="vertical" />}>
                  <span>{resolveCompetitionType(competition.type)}</span>
                  <span>{competition.location}</span>
                  <span>{competition.startDate}</span>
                  <span>{competition.organizerClub.name}</span>
                </Space>
              </Col>
            }
            extra={[getCalendarButton()]}
          />
          <BackTop />
          {competition.stage === "APPLICATION" &&
            ownEntries &&
            ownEntries.length > 0 && (
              <Row gutter={[16, 16]}>
                <Col xs={0} md={24}>
                  <Card title={"Leadott nevezéseid"}>
                    {entries && (
                      <EntryEditor
                        entries={ownEntries}
                        competition={competition}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
            )}
          <Row gutter={[16, 16]}>
            {/* XS */}
            {competition.stage !== "APPLICATION" && (
              <>
                <Col xs={24} md={0}>
                  <Space
                    split={<Divider type="vertical" />}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <span>{resolveCompetitionType(competition.type)}</span>
                    <span>{competition.location}</span>
                    <span>{competition.startDate}</span>
                  </Space>
                </Col>
                <Col xs={24} md={0}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {competition.organizerClub.name}
                  </div>
                </Col>
              </>
            )}
            <Col xs={24} md={0}>
              {competition.stage === "SCORED" && entries && (
                <>
                  <PlacementTab
                    localEntries={entries}
                    localCompetitionData={competition}
                  />
                  <Card title={"Versenykiírás"}>
                    <Description competition={competition} />
                  </Card>
                </>
              )}
              {competition.stage === "INFO" && (
                <Card title={"Versenykiírás"}>
                  <Description competition={competition} />
                </Card>
              )}
              {competition.stage === "APPLICATION" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: " 16px",
                    }}
                  >
                    <Space split={<Divider type="vertical" />}>
                      <span>{resolveCompetitionType(competition.type)}</span>
                      <span>{competition.location}</span>
                      <span>{competition.startDate}</span>
                    </Space>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: " 16px",
                    }}
                  >
                    <span>{competition.organizerClub.name}</span>
                  </div>

                  <Tabs defaultActiveKey="1" centered tabBarGutter={24}>
                    <TabPane tab="Jelentkezés" key="1">
                      {competition.stage === "APPLICATION" &&
                        ownEntries &&
                        ownEntries.length > 0 && (
                          <Col xs={24}>
                            {entries && (
                              <EntryEditor
                                entries={ownEntries}
                                competition={competition}
                              />
                            )}
                          </Col>
                        )}

                      <div className={"application-deadline"}>
                        <Space split={<Divider type="vertical" />}>
                          {competition.maxParticipants < 999 && (
                            <span>
                              <strong>Max jelentkezők:</strong>{" "}
                              {competition.maxParticipants}
                            </span>
                          )}
                          <span>
                            <strong>Jelentkezési határidő:</strong>{" "}
                            {moment(competition.applicationDeadline).format(
                              "YYYY.MM.DD. - HH:mm"
                            )}
                          </span>
                        </Space>
                      </div>
                      <Divider type={"horizontal"}></Divider>

                      <ApplicationForm
                        form={form}
                        onSubmitEntry={onSubmitEntry}
                        userData={userData}
                        competition={competition}
                        clubs={clubs}
                        isLoadingInsertEntry={isLoadingInsertEntry}
                        entriesNumber={entries ? entries.length : 0}
                      />
                    </TabPane>
                    <TabPane tab="Versenykiírás" key="2">
                      <Description competition={competition} />
                    </TabPane>
                    <TabPane
                      tab={`Nevezők (${entries ? entries.length : "-"})`}
                      key="3"
                    >
                      <Col xs={24} sm={0}>
                        {entries && _.isNumber(entries[0].team) && (
                          <TeamedEntriesList
                            entries={entries}
                            isFetchingEntries={isFetchingEntries}
                          ></TeamedEntriesList>
                        )}
                        {entries && !_.isNumber(entries[0].team) && (
                          <EntriesList
                            entries={entries}
                            isFetchingEntries={isFetchingEntries}
                          ></EntriesList>
                        )}
                      </Col>
                      <Col xs={0} sm={24}>
                        <EntriesTable
                          entries={entries}
                          isFetchingEntries={isFetchingEntries}
                          competition={competition}
                        />
                      </Col>
                    </TabPane>
                  </Tabs>
                </>
              )}
            </Col>

            {/* MD */}

            <Col xs={0} md={24} xxl={0}>
              <Row gutter={[16, 16]}>
                {competition.stage === "APPLICATION" && (
                  <Col xs={24} className="gutter-row">
                    <Card
                      title={"Nevezés"}
                      extra={
                        <Space split={<Divider type="vertical" />}>
                          {competition.maxParticipants < 999 && (
                            <span>
                              <strong>Max jelentkezők:</strong>{" "}
                              {competition.maxParticipants}
                            </span>
                          )}
                          <span>
                            <strong>Határidő:</strong>{" "}
                            {moment(competition.applicationDeadline).format(
                              "YYYY.MM.DD. - HH:mm"
                            )}
                          </span>
                        </Space>
                      }
                    >
                      <ApplicationForm
                        form={form}
                        onSubmitEntry={onSubmitEntry}
                        userData={userData}
                        competition={competition}
                        clubs={clubs}
                        isLoadingInsertEntry={isLoadingInsertEntry}
                        entriesNumber={entries ? entries.length : 0}
                      />
                    </Card>
                  </Col>
                )}
                {competition.stage === "SCORED" && entries && (
                  <Col xs={24} xl={14} className="gutter-row">
                    <PlacementTab
                      localEntries={entries}
                      localCompetitionData={competition}
                    />
                  </Col>
                )}
                {competition.stage === "APPLICATION" && (
                  <Col xs={24} xl={14}>
                    <Card
                      title={"Nevezők listája"}
                      className="gutter-row"
                      extra={
                        <Typography.Text strong>
                          {(entries || []).length}{" "}
                          {competition.maxParticipants < 999
                            ? " / " + competition.maxParticipants
                            : ""}{" "}
                          nevező
                        </Typography.Text>
                      }
                    >
                      <CategoriesBarChart entries={entries} />
                      <EntriesTable
                        entries={entries}
                        isFetchingEntries={isFetchingEntries}
                        competition={competition}
                      />
                    </Card>
                  </Col>
                )}
                <Col md={24} xl={competition.stage === "INFO" ? 24 : 10}>
                  <Card title={"Versenykiírás"}>
                    <Description competition={competition} />
                  </Card>
                </Col>
              </Row>
            </Col>

            {/* XXL */}

            <Col xs={0} xxl={24}>
              <Row gutter={[16, 16]}>
                {competition.stage !== "INFO" && (
                  <Col xs={14}>
                    {competition.stage === "SCORED" && entries && (
                      <Col xs={24} className="gutter-row">
                        <PlacementTab
                          localEntries={entries}
                          localCompetitionData={competition}
                        />
                      </Col>
                    )}
                    {competition.stage === "APPLICATION" && (
                      <>
                        <Card
                          title={"Nevezés"}
                          style={{ marginBottom: "16px" }}
                          extra={
                            <Space split={<Divider type="vertical" />}>
                              {competition.maxParticipants < 999 && (
                                <span>
                                  <strong>Max jelentkezők:</strong>{" "}
                                  {competition.maxParticipants}
                                </span>
                              )}
                              <span>
                                <strong>Határidő: </strong>
                                {moment(competition.applicationDeadline).format(
                                  "YYYY.MM.DD. - HH:mm"
                                )}
                              </span>
                            </Space>
                          }
                        >
                          <ApplicationForm
                            form={form}
                            onSubmitEntry={onSubmitEntry}
                            userData={userData}
                            competition={competition}
                            clubs={clubs}
                            isLoadingInsertEntry={isLoadingInsertEntry}
                            entriesNumber={entries ? entries.length : 0}
                          />
                        </Card>
                        <Card
                          title={"Nevezők listája"}
                          extra={
                            <Typography.Text strong>
                              {(entries || []).length}{" "}
                              {competition.maxParticipants < 999
                                ? " / " + competition.maxParticipants
                                : ""}{" "}
                              nevező
                            </Typography.Text>
                          }
                        >
                          <CategoriesBarChart entries={entries} />
                          <EntriesTable
                            entries={entries}
                            isFetchingEntries={isFetchingEntries}
                            competition={competition}
                          />
                        </Card>
                      </>
                    )}
                  </Col>
                )}

                <Col xs={competition.stage === "INFO" ? 16 : 10}>
                  <Card title={"Versenykiírás"}>
                    <Description competition={competition} />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
