import React from "react";
import { Tag } from "antd";

export const GenderTag = ({ gender }) => {
  return (
    <>
      {{
        MALE: <Tag color={"blue"}>Férfi</Tag>,
        FEMALE: <Tag color={"magenta"}>Nő</Tag>,
      }[gender] || <Tag color={"blue"}>{gender}</Tag>}
    </>
  );
};
