import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getManagedCompetitions } from "../api/Competition";
import { Card, Col, Button, PageHeader, Space, Table } from "antd";
import { Link } from "react-router-dom";
import {
  SettingOutlined,
  UserOutlined,
  TableOutlined,
  SaveOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router";
import { db } from "../utils/LocalDB";
import { useNetworkStatus } from "../networkStatus/NetworkStatus";

export const ManagerDashboard = () => {
  const { Column } = Table;

  let history = useHistory();
  let networkStatus = useNetworkStatus();

  const [localCompetitions, setLocalCompetitions] = useState([]);

  useEffect(() => {
    db.competitions.toArray().then((lc) => setLocalCompetitions(lc));
  }, []);

  const { data, isLoading } = useQuery(
    "managedcompetitions",
    getManagedCompetitions
  );

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push("/")}
        title={"Vezérlőpult"}
      />
      <Card title={"Versenyek"} bodyStyle={{ padding: "1px 0 0 0" }}>
        <Table
          dataSource={networkStatus ? data : localCompetitions}
          loading={isLoading}
          showHeader={false}
        >
          <Column
            title={"Név"}
            dataIndex={"name"}
            render={(text, record) => (
              <Space>
                {localCompetitions.find((comp) => comp.id === record.id) && (
                  <SaveOutlined />
                )}
                <span>{text}</span>
              </Space>
            )}
          />
          <Column
            align={"right"}
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <Link to={`/competition/edit/${record.id}`}>
                  <Button disabled={!networkStatus}>
                    <Col xs={24} sm={0}>
                      <SettingOutlined />
                    </Col>
                    <Col xs={0} sm={24}>
                      Szerkesztés
                    </Col>
                  </Button>
                </Link>
                {record.stage != "INFO" && (
                  <>
                    <Link
                      to={`/manage-competition/${record.id}/${record.slug}`}
                    >
                      <Button disabled={!networkStatus}>
                        <Col xs={24} sm={0}>
                          <UserOutlined />
                        </Col>
                        <Col xs={0} sm={24}>
                          Nevezők
                        </Col>
                      </Button>
                    </Link>
                    <Link to={`/manage-teams/${record.id}/${record.slug}`}>
                      <Button disabled={!networkStatus}>
                        <Col xs={24} sm={0}>
                          <UsergroupAddOutlined />
                        </Col>
                        <Col xs={0} sm={24}>
                          Csapatbeosztás
                        </Col>
                      </Button>
                    </Link>
                    <Link to={`/manager/${record.id}/${record.slug}`}>
                      <Button
                        disabled={
                          !networkStatus &&
                          !localCompetitions.find(
                            (comp) => comp.id === record.id
                          )
                        }
                      >
                        <Col xs={24} sm={0}>
                          <TableOutlined />
                        </Col>
                        <Col xs={0} sm={24}>
                          Pontszámok
                        </Col>
                      </Button>
                    </Link>
                  </>
                )}
              </Space>
            )}
          />
        </Table>
      </Card>
    </>
  );
};
