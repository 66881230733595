import React from "react";
import { Space, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { CategoryTag } from "../tag/CategoryTag";
import { AgeGroupTag } from "../tag/AgeGroupTag";
import { GenderTag } from "../tag/GenderTag";

export const GroupElement = ({ entry }) => {
  return (
    <Tooltip
      key={entry.id}
      placement="topLeft"
      title={entry.comment ? entry.comment : ""}
    >
      <div className={"entry"} key={entry.id}>
        <div className={"entry-left"}>
          <div className={"entry-name"}>
            <div>
              <Space>
                {entry.comment && (
                  <InfoCircleOutlined
                    style={{
                      color: "#258f17",
                    }}
                  />
                )}
                <span>{entry.name}</span>
              </Space>
            </div>
            <div className={"entry-club"}>{entry.club.name}</div>
          </div>
        </div>
        <div className={"entry-right"}>
          <div>
            <CategoryTag category={entry.category.name} />
          </div>
          <div>
            <AgeGroupTag ageGroup={entry.ageGroup.ageGroup.name} />
          </div>
          <div>
            <GenderTag gender={entry.gender} />
          </div>
        </div>
      </div>
    </Tooltip>
  );
};
