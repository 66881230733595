import { Select } from "antd";
import React from "react";

const { Option } = Select;

export const ClubInput = ({ clubs, ...restProps }) => {
  return (
    <Select
      {...restProps}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      <Option value={999}>Egyéb</Option>
      {clubs &&
        clubs.map((club) => (
          <Option key={club.id} value={club.id}>
            {club.name}
          </Option>
        ))}
    </Select>
  );
};
