import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Card,
  Tabs,
  PageHeader,
  BackTop,
  Skeleton,
} from "antd";
import { useQuery } from "react-query";
import { getTournament } from "../api/Tournament";
import "./Tournament.less";
import DOMPurify from "dompurify";
import { useHistory } from "react-router";
import moment from "moment";

const { Meta } = Card;
const { TabPane } = Tabs;

export const Tournament = () => {
  let history = useHistory();
  let { tournamentId, slug } = useParams();

  const { data: tournament, isLoading } = useQuery(
    ["tournament", parseInt(tournamentId)],
    getTournament
  );

  const getRomanNumeral = (number) => {
    return ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"][
      number
    ];
  };

  const getNextRound = () => {
    return (
      tournament &&
      tournament.competitions.find((competition) =>
        moment(competition.startDate).isAfter(moment(), "day")
      )
    );
  };

  let nextRound = getNextRound();

  return (
    <>
      <BackTop />
      {isLoading && <Skeleton active paragraph={{ rows: 10 }} />}
      {tournament && (
        <>
          <PageHeader
            className="site-page-header"
            onBack={() => history.push("/competitions")}
            title={tournament.name}
            extra={[
              nextRound ? (
                <Link
                  key={"next"}
                  to={`/competition/${nextRound.id}/${nextRound.slug}`}
                >
                  <Button key="2" type="primary">
                    A következő forduló
                  </Button>
                </Link>
              ) : null,
              <Link
                key={"sum"}
                to={`/tournament/${tournamentId}/${slug}/summary`}
              >
                <Button key="3">Összesítés</Button>
              </Link>,
            ]}
          />
          <Row gutter={[16, 16]}>
            <Col
              xs={24}
              xxl={{ span: 14, push: 10 }}
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
                paddingTop: "52px",
                alignContent: "flex-start",
              }}
            >
              {tournament &&
                tournament.competitions.map((competition, index) => (
                  <Card
                    key={competition.id}
                    style={{
                      minWidth: 300,
                      maxHeight: "375px",
                      marginBottom: "16px",
                    }}
                    cover={
                      <div className={"competition-card-bg"}>
                        <div className={"club-logo"}>
                          <img
                            alt={""}
                            src={`/images/club_${competition.organizerClub.id}.png`}
                          />
                        </div>
                      </div>
                    }
                    actions={[
                      <Link
                        key={competition.id}
                        to={`/competition/${competition.id}/${competition.slug}`}
                      >
                        <Button
                          key={"details"}
                          style={{ width: "calc(100% - 10px)" }}
                        >
                          Részletek
                        </Button>
                      </Link>,
                    ]}
                  >
                    <Meta
                      title={`${getRomanNumeral(index)}. Forduló - ${
                        competition.location
                      }`}
                      description={`${competition.startDate} - ${competition.organizerClub.name}`}
                    />
                  </Card>
                ))}
            </Col>
            <Col xs={24} xxl={{ span: 10, pull: 14 }}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="A versenysorozatról" key="1">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(tournament.description),
                    }}
                  ></div>
                </TabPane>
                <TabPane tab="Szabályzat" key="2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(tournament.rules),
                    }}
                  ></div>
                </TabPane>
                <TabPane tab="Dokumentumok" key="3">
                  Hamarosan...
                </TabPane>
                <TabPane tab="Galéria" key="4">
                  Hamarosan...
                </TabPane>
                <TabPane tab="Kapcsolat" key="5">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(tournament.contact),
                    }}
                  ></div>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
