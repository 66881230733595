import { List, Skeleton } from "antd";
import React from "react";

import "./EntriesList.less";
import { GenderTag } from "../tag/GenderTag";
import { CategoryTag } from "../tag/CategoryTag";
import { AgeGroupTag } from "../tag/AgeGroupTag";

export const EntriesList = ({ entries, isFetchingEntries }) => {
  return (
    <div className={"competition-entries-list"}>
      <List
        dataSource={entries}
        loading={isFetchingEntries}
        renderItem={(entry) => (
          <List.Item>
            <Skeleton avatar title={false} loading={isFetchingEntries} active>
              <List.Item.Meta
                title={
                  <div className={"entry"}>
                    <div className={"entry-left"}>
                      <div className={"entry-name"}>
                        <div>{entry.name}</div>
                        <div className={"entry-club"}>{entry.club.name}</div>
                      </div>
                    </div>
                    <div className={"entry-right"}>
                      <div>
                        <CategoryTag category={entry.category.name} />
                      </div>
                      <div>
                        <AgeGroupTag ageGroup={entry.ageGroup.ageGroup.name} />
                      </div>
                      <div>
                        <GenderTag gender={entry.gender} />
                      </div>
                    </div>
                  </div>
                }
              />
            </Skeleton>
          </List.Item>
        )}
      />
    </div>
  );
};
