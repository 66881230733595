import { Select } from "antd";
import React from "react";

const { Option } = Select;

export const GenderInput = ({ ...restProps }) => {
  return (
    <Select {...restProps}>
      <Option key={"MALE"} value="MALE">
        Férfi
      </Option>
      <Option key={"FEMALE"} value="FEMALE">
        Nő
      </Option>
    </Select>
  );
};
