import { Form, Input } from "antd";
import { GenderInput } from "../entryForm/GenderInput";
import { CategoryInput } from "../entryForm/CategoryInput";
import { AgeGroupInput } from "../entryForm/AgeGroupInput";
import { ClubInput } from "../entryForm/ClubInput";
import { LunchInput } from "../entryForm/LunchInput";
import React from "react";

export const EditableCell = ({
  editing,
  dataIndex,
  children,
  ...restProps
}) => {
  let inputNode;

  let dataIndexString = Array.isArray(dataIndex) ? dataIndex[0] : dataIndex;

  switch (dataIndexString) {
    case "gender":
      inputNode = (
        <Form.Item
          name="gender"
          rules={[
            {
              required: true,
              message: "Válassz nemet!",
            },
          ]}
        >
          <GenderInput />
        </Form.Item>
      );
      break;
    case "category":
      inputNode = (
        <Form.Item
          name="category"
          rules={[
            {
              required: true,
              message: "Válassz kategóriát!",
            },
          ]}
        >
          <CategoryInput categories={restProps.competition.categories} />
        </Form.Item>
      );
      break;
    case "ageGroup":
      inputNode = (
        <Form.Item
          name="ageGroup"
          rules={[
            {
              required: true,
              message: "Válassz korosztályt!",
            },
          ]}
        >
          <AgeGroupInput ageGroups={restProps.competition.ageGroups} />
        </Form.Item>
      );
      break;
    case "club":
      inputNode = (
        <Form.Item
          name="club"
          rules={[
            {
              required: true,
              message: "Válaszd ki az egyesületed!",
            },
          ]}
        >
          <ClubInput clubs={restProps.clubs} />
        </Form.Item>
      );
      break;
    case "lunch":
      inputNode = (
        <Form.Item
          name="lunch"
          rules={[
            {
              required: true,
              message: "Add meg hány ebédet kérsz!",
            },
          ]}
        >
          <LunchInput />
        </Form.Item>
      );
      break;
    default:
      inputNode = (
        <Form.Item name={dataIndexString}>
          <Input />
        </Form.Item>
      );
  }

  return <td {...restProps}>{editing ? inputNode : children}</td>;
};
