import React, { useState } from "react";

import { Col, Row, Form, Input, Button, Alert } from "antd";
import { FacebookOutlined, GoogleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { useAuth } from "../auth/Auth";

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [facebookLoading, setFacebookLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [error, setError] = useState(null);
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  let { from } = location.state || { from: { pathname: "/" } };

  let login = (values) => {
    setLoading(true);
    setError(null);
    auth
      .signin(values)
      .then(() => {
        console.log("repl");
        history.replace(from);
      })
      .catch((error) => {
        setError(
          error.response.status === 401
            ? "Hibás felhasználónév vagy jelszó"
            : "Hiba történt, próbáld újra!"
        );
      })
      .finally(() => setLoading(false));
  };

  const onFinish = (values) => {
    login(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Col
        xs={{ span: 24 }}
        md={{ span: 8, offset: 8 }}
        xxl={{ span: 6, offset: 9 }}
      >
        <h2>Bejelentkezés</h2>
        <Form
          requiredMark={"optional"}
          layout={"vertical"}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Email"
            name="username"
            rules={[{ required: true, message: "Add meg a felhasználóneved!" }]}
          >
            <Input id="username" />
          </Form.Item>

          <Form.Item
            label="Jelszó"
            name="password"
            rules={[{ required: true, message: "Add meg a jelszavad!" }]}
          >
            <Input.Password id="password" />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            Új vagy itt?{" "}
            <Link to={"/register"} id={"register-link"}>
              Regisztrálj
            </Link>
          </Form.Item>
          {error && (
            <Form.Item>
              <Alert id="error-message" message={error} type="error" />
            </Form.Item>
          )}
          <Form.Item>
            <Row gutter={[16, 16]}>
              <Col xs={24} xl={8}>
                <Button
                  id="login-button"
                  block
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={facebookLoading || googleLoading}
                >
                  Bejelentkezés
                </Button>
              </Col>
              <Col xs={24} xl={8}>
                <Button
                  block
                  onClick={() => setFacebookLoading(true)}
                  href={
                    process.env.REACT_APP_API_URL + "/oauth2/authorize/facebook"
                  }
                  type="primary"
                  htmlType="submit"
                  icon={<FacebookOutlined />}
                  loading={facebookLoading}
                  disabled={loading || googleLoading}
                >
                  Facebook
                </Button>
              </Col>
              <Col xs={24} xl={8}>
                <Button
                  block
                  onClick={() => setGoogleLoading(true)}
                  href={
                    process.env.REACT_APP_API_URL + "/oauth2/authorize/google"
                  }
                  type="primary"
                  htmlType="submit"
                  icon={<GoogleOutlined />}
                  loading={googleLoading}
                  disabled={loading || facebookLoading}
                >
                  Google
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </>
  );
};
