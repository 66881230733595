import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getCompetitions = async () => {
  const { data } = await axios(`${API_URL}/competition`);
  return data;
};

export const getCompetitionsThisWeek = async () => {
  const { data } = await axios(`${API_URL}/competitions-this-week`);
  return data;
};

export const getCompetition = async (key, id) => {
  const { data } = await axios(`${API_URL}/competition/${id}`);
  return data;
};

export const getManagedCompetition = async (key, id) => {
  const { data } = await axios(`${API_URL}/managed-competition/${id}`);
  return data;
};

export const getManagedCompetitionEntries = async (key, id) => {
  const { data } = await axios(`${API_URL}/managed-competition/${id}/entry`);
  return data;
};

export const getManagedCompetitions = async () => {
  const { data } = await axios(`${API_URL}/managed-competition`);
  return data;
};

export const managedUpdateEntry = async ({ competitionId, entry }) => {
  const { data } = await axios.put(
    `${API_URL}/managed-competition/${competitionId}/entry`,
    entry
  );
  return data;
};

export const insertCompetition = async (competition) => {
  const { data } = await axios.post(`${API_URL}/competition`, competition);
  return data;
};

export const updateCompetition = async ({ competitionId, competition }) => {
  const { data } = await axios.put(
    `${API_URL}/competition/${competitionId}`,
    competition
  );
  return data;
};

export const getEntries = async (key, id) => {
  const { data } = await axios(`${API_URL}/competition/${id}/entry`);
  return data;
};

export const getOwnEntries = async (key, id) => {
  const { data } = await axios(`${API_URL}/competition/${id}/own-entries`);
  return data;
};

export const insertEntry = async ({ competitionId, entry }) => {
  const { data } = await axios.post(
    `${API_URL}/competition/${competitionId}/entry`,
    entry
  );
  return data;
};

export const updateEntries = async ({ competitionId, entries }) => {
  const { data } = await axios.put(
    `${API_URL}/competition/${competitionId}/entry`,
    entries
  );
  return data;
};

export const updateTeams = async ({ competitionId, entries }) => {
  const { data } = await axios.put(
    `${API_URL}/competition/${competitionId}/entry/teams`,
    entries
  );
  return data;
};

export const updateEntry = async (entry) => {
  const { data } = await axios.put(`${API_URL}/competition-entry`, entry);
  return data;
};

export const deleteEntry = async (entryId) => {
  return axios.delete(`${API_URL}/competition-entry/${entryId}`);
};

export const downloadExcel = async (competitionId) => {
  axios({
    url: `${API_URL}/competition/${competitionId}/excel`,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      response.headers["content-disposition"].substr(21)
    );
    document.body.appendChild(link);
    link.click();
  });
};
