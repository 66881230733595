import { Button, Divider, Form, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { CreateProfileModal } from "../createProfileModal/CreateProfileModal";
import PropTypes from "prop-types";

const { Option } = Select;

export const ProfileInput = ({ profiles, onSelect }) => {
  const [createProfileModalVisible, setCreateProfileModalVisible] = useState(
    false
  );

  return (
    <>
      <Form.Item
        label="Név"
        name="profile"
        rules={[
          {
            required: true,
            message: "Add meg a nevet!",
          },
        ]}
      >
        <Select
          onChange={(profileId) => {
            let selectedProfile = profiles.find(
              (profile) => profile.id === profileId
            );
            onSelect(selectedProfile);
          }}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ padding: 8 }}>
                <Button
                  type={"primary"}
                  style={{ width: "100%" }}
                  icon={<PlusOutlined />}
                  onClick={() => setCreateProfileModalVisible(true)}
                >
                  Új profil felvétele
                </Button>
              </div>
            </div>
          )}
        >
          {profiles.map((profile) => (
            <Option key={profile.id} value={profile.id}>
              {profile.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <CreateProfileModal
        visible={createProfileModalVisible}
        onCreate={(profile) => {
          setCreateProfileModalVisible(false);
          onSelect(profile);
        }}
        onCancel={() => setCreateProfileModalVisible(false)}
      />
    </>
  );
};

ProfileInput.propTypes = {
  onSelect: PropTypes.func,
};
