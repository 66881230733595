import React from "react";
import { Bar } from "@ant-design/charts";

export const CategoriesBarChart = ({ entries }) => {
  if (!entries) {
    return null;
  }

  const colors = {
    CRB: "#f5222d",
    CU: "#fa8c16",
    HU: "#faad14",
    CB: "#fa541c",
    OL: "#52c41a",
    BB: "#13c2c2",
    TRRB: "#1890ff",
    TRLB: "#eb2f96",
    PBHB: "#722ed1",
  };

  const categoryMap = {};

  entries.forEach((entry) => {
    if (!categoryMap[entry.category.name]) {
      categoryMap[entry.category.name] = 1;
    } else {
      categoryMap[entry.category.name] += 1;
    }
  });

  const options = {
    legend: false,
    colorField: "category", // 部分图表使用 seriesField
    color: ({ category }) => {
      return colors[category] || "#1890ff";
    },
    height: 35,
    xField: "count",
    xAxis: false,
    yAxis: false,
    yField: "group",
    seriesField: "category",
    isStack: true,
    label: {
      position: "middle",
      content: function content(item) {
        return item.category;
      },
      style: { fill: "#fff" },
    },
  };

  const getData = () => {
    let data = Object.entries(categoryMap).map(([category, count]) => ({
      category,
      count,
      group: "Kategóriák",
    }));

    data.sort((a, b) => a.count - b.count);

    return data;
  };

  return <Bar {...options} data={getData()} />;
};
