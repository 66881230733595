export const groupEntries = (entries) => {
  let categories = {};

  entries.forEach((entry) => {
    let categoryId = entry.category.id + entry.ageGroup.id + entry.gender;
    if (!categories[categoryId]) {
      categories[categoryId] = {
        category: entry.category,
        ageGroup: entry.ageGroup,
        gender: entry.gender,
        entries: [],
      };
    }

    categories[categoryId].entries.push(entry);
  });

  Object.values(categories).forEach((category) =>
    category.entries.sort((a, b) => a.placement - b.placement)
  );

  return categories;
};
