import React from "react";
import { Button, PageHeader } from "antd";
import { useMutation } from "react-query";
import { updateTeams } from "../api/Competition";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";

export const TeamsPageHeader = ({ competition, entries }) => {
  const history = useHistory();
  let { competitionId } = useParams();

  const [updateEntriesMutation, { isLoading: isLoadingUpdate }] = useMutation(
    updateTeams,
    {
      onSuccess: () => {
        Swal.fire({
          title: "Sikeres mentés!",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      },
    }
  );

  return (
    <PageHeader
      className="site-page-header"
      onBack={() => history.push("/manager-dashboard")}
      title={`${competition.name} - Csapatbeosztás`}
      extra={[
        <Button
          id="save-teams-button"
          key="2"
          type="primary"
          loading={isLoadingUpdate}
          onClick={() => {
            updateEntriesMutation({
              competitionId: parseInt(competitionId),
              entries: Object.values(entries)
                .reduce(
                  (acc, arr) => [
                    ...acc,
                    ...arr.reduce(
                      (acc, group) => [...acc, ...group.entries],
                      []
                    ),
                  ],
                  []
                )
                .map(({ id, team, teamIndex, groupId }) => ({
                  id,
                  team,
                  teamIndex,
                  groupId,
                })),
            });
          }}
        >
          Mentés és publikálás
        </Button>,
      ]}
    />
  );
};
