import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { Button, Card, Col, Dropdown, Menu, Row, Tooltip } from "antd";
import { CardExtras } from "./CardExtras";
import { Group } from "./Group";
import { SwapOutlined } from "@ant-design/icons";

export const TeamsCol = ({
  entries,
  unlockGroup,
  setEntries,
  setTeamIndexes,
}) => {
  const swapTeams = (fromTeam, toTeam) => {
    console.log("swap teams", fromTeam, toTeam);

    let tempEntriesMap = { ...entries };

    tempEntriesMap[fromTeam].forEach((group) => (group.team = toTeam));
    tempEntriesMap[toTeam].forEach((group) => (group.team = fromTeam));

    let temp = tempEntriesMap[fromTeam];
    tempEntriesMap[fromTeam] = tempEntriesMap[toTeam];
    tempEntriesMap[toTeam] = temp;

    setTeamIndexes(tempEntriesMap);

    setEntries(tempEntriesMap);
  };

  const getSwapMenu = (fromTeam) => (
    <Menu style={{ height: "200px", overflowY: "auto" }}>
      {[...Array(20).keys()].map((teamNumber) => (
        <Menu.Item
          key={teamNumber}
          onClick={() => swapTeams(fromTeam, teamNumber + 1)}
        >
          {teamNumber + 1}. csapat
        </Menu.Item>
      ))}
    </Menu>
  );

  const swapButton = (team) => {
    return (
      <Tooltip key={2} title={"Csapat megcserélése"}>
        <Dropdown overlay={getSwapMenu(team)} trigger={"click"}>
          <Button style={{ marginLeft: "20px" }}>
            <SwapOutlined />
          </Button>
        </Dropdown>
      </Tooltip>
    );
  };

  return (
    <Row gutter={[8, 8]}>
      {[...Array(20).keys()].map((teamNumber) => (
        <Droppable
          droppableId={`team-${teamNumber + 1}`}
          key={teamNumber + 1}
          isCombineEnabled
        >
          {(provided, snapshot) => (
            <Col xs={24} lg={12} xxl={8}>
              <Card
                key={teamNumber + 1}
                title={`${teamNumber + 1}. csapat`}
                extra={[
                  <CardExtras
                    key={1}
                    team={teamNumber + 1}
                    entries={entries}
                  />,
                  swapButton(teamNumber + 1),
                ]}
                style={{
                  //height: "600px",
                  backgroundColor: snapshot.isDraggingOver
                    ? "#f3fff3"
                    : "white",
                }}
              >
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {entries[teamNumber + 1] &&
                    entries[teamNumber + 1].map((group) => (
                      <Group
                        key={group.id}
                        group={group}
                        unlockGroup={unlockGroup}
                      />
                    ))}
                  {provided.placeholder}
                </div>
              </Card>
            </Col>
          )}
        </Droppable>
      ))}
    </Row>
  );
};
