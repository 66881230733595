import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

const init = () => {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  ReactGA.initialize(TRACKING_ID, { debug: isDev });
};

const sendEvent = (payload) => {
  ReactGA.event(payload);
};

const sendPageview = (path) => {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
};

export const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const currentPath =
      location.pathname +
      (location.search.indexOf("auth_token") > -1 ? "" : location.search);

    sendPageview(currentPath);
  }, [location]);

  return {
    sendEvent,
    sendPageview,
  };
};
