import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { ProfileInput } from "../entryForm/ProfileInput";
import { GenderInput } from "../entryForm/GenderInput";
import { CategoryInput } from "../entryForm/CategoryInput";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { AgeGroupInput } from "../entryForm/AgeGroupInput";
import { ClubInput } from "../entryForm/ClubInput";
import { LunchInput } from "../entryForm/LunchInput";
import React from "react";
import moment from "moment";
import { useAuth } from "../auth/Auth";
import { useQuery } from "react-query";
import { getUserData } from "../api/User";
import "./ApplicationForm.less";
import { Link } from "react-router-dom";

const { Title } = Typography;

export const ApplicationForm = ({
  form,
  onSubmitEntry,
  competition,
  clubs,
  isLoadingInsertEntry,
  entriesNumber,
}) => {
  const auth = useAuth();

  const { data: userData } = useQuery(["userData"], getUserData, {
    enabled: auth.user,
  });

  return (
    <>
      {moment(competition.applicationDeadline).isBefore(moment()) && (
        <div className={"application-closed-overlay"}>
          <Title level={3}>A nevezés lezárult!</Title>
        </div>
      )}
      {competition.maxParticipants <= entriesNumber && (
        <div className={"application-closed-overlay"}>
          <Title level={3}>A létszám betelt!</Title>
        </div>
      )}
      {moment(competition.applicationDeadline).isAfter(moment()) &&
        competition.maxParticipants > entriesNumber && (
          <Form
            name="entry"
            form={form}
            layout={"vertical"}
            requiredMark={"optional"}
            initialValues={{
              name: "",
              gender: "",
              category: "",
              ageGroup: "",
              club: "",
              lunch: "",
              agree: false,
              profile: "",
            }}
            onFinish={(values) => {
              console.log("onfinish", values);
              onSubmitEntry(values);
            }}
            onFinishFailed={(error) => console.log("onfinishfailed", error)}
          >
            <Row gutter={[16, 16]}>
              {!auth.user && (
                <Col xs={24}>
                  <div className={"call-to-register"}>
                    Versenyzői profilok, statisztikák használatához és a leadott
                    nevezéseid kezeléséhez
                    <Link to={"/register"}>
                      <strong> regisztrálj </strong>
                    </Link>
                    Íjász.NET fiókot vagy
                    <Link to={"/login"}>
                      <strong> jelentkezz be </strong>
                    </Link>
                    Facebook-al, Google-el!
                  </div>
                </Col>
              )}
              <Col xs={24} sm={6}>
                {!auth.user && (
                  <Form.Item
                    label="Név"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Add meg a neved!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
                {auth.user && userData && (
                  <ProfileInput
                    profiles={userData.profiles}
                    onSelect={(profile) => {
                      form.setFieldsValue({
                        profile: profile.id,
                        gender: profile.gender,
                        club: profile.club.id,
                        permitNumber: profile.permitNumber,
                        email: userData.email,
                        ageGroup: competition.ageGroups
                          .sort((a, b) => b.fromAge - a.fromAge)
                          .find(
                            (ageGroup) =>
                              moment(competition.startDate)
                                .startOf("year")
                                .diff(moment(profile.birthday), "years") >=
                              ageGroup.fromAge
                          ).id,
                      });
                    }}
                  />
                )}
              </Col>
              <Col xs={24} sm={3}>
                <Form.Item
                  label="Nem"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Válassz nemet!",
                    },
                  ]}
                >
                  <GenderInput />
                </Form.Item>
              </Col>
              <Col xs={24} sm={3}>
                <Form.Item
                  label="Kategória"
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "Válassz kategóriát!",
                    },
                  ]}
                >
                  <CategoryInput categories={competition.categories} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={5}>
                <Form.Item
                  label={
                    <div>
                      <Space>
                        <span>Korosztály</span>
                        <Tooltip
                          placement="rightTop"
                          title={"A január 1-én betöltött kor számít."}
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </Space>
                    </div>
                  }
                  name="ageGroup"
                  rules={[
                    {
                      required: true,
                      message: "Válassz korosztályt!",
                    },
                  ]}
                >
                  <AgeGroupInput ageGroups={competition.ageGroups} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={7}>
                <Form.Item
                  label="Egyesület"
                  name="club"
                  rules={[
                    {
                      required: true,
                      message: "Válaszd ki az egyesületed!",
                    },
                  ]}
                >
                  <ClubInput clubs={clubs} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={3}>
                <Form.Item
                  label="Ebéd"
                  name="lunch"
                  rules={[
                    {
                      required: true,
                      message: "Add meg hány ebédet kérsz!",
                    },
                  ]}
                >
                  <LunchInput />
                </Form.Item>
              </Col>
              {!userData && (
                <Col xs={24} sm={6}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Add meg az email címed!",
                      },
                    ]}
                  >
                    <Input type={"email"} />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} sm={3}>
                <Form.Item
                  label="Versenyeng."
                  name="permitNumber"
                  rules={[
                    {
                      required: true,
                      message: "Add meg a versenyengedélyed számát!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={userData ? 18 : 12}>
                <Form.Item label="Megjegyzés, csapatbeosztás" name="comment">
                  <Input />
                </Form.Item>
              </Col>
              {competition.applicationInfoMessage && (
                <Col xs={24}>
                  <Alert
                    message="Figyelem!"
                    description={competition.applicationInfoMessage}
                    type="warning"
                    showIcon
                  />
                </Col>
              )}
              <Col flex={"auto"}>
                <Form.Item
                  name="agree"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (value) {
                          callback();
                        } else {
                          callback([new Error("El kell fogadni!")]);
                        }
                      },
                    },
                  ]}
                >
                  <Checkbox>
                    A versenyszabályzatot megismertem és elfogadom!
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col flex="100px">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoadingInsertEntry}
                  >
                    Nevezés leadása
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
    </>
  );
};
