import { Select } from "antd";
import React from "react";
import PropTypes from "prop-types";

const { Option } = Select;

export const CategoryInput = ({ categories, ...restProps }) => {
  return (
    <Select {...restProps} listHeight={288}>
      {categories
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((category) => (
          <Option key={category.id} value={category.id}>
            {category.name}
          </Option>
        ))}
    </Select>
  );
};

CategoryInput.propTypes = {
  categories: PropTypes.array,
};
